import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import classnames from "classnames";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      errors: {}
    };
  }
  componentDidMount(props) {
    document.title = "Login"
    // If logged in and user navigates to Login page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.auth.isAuthenticated) {
      nextProps.history.push("/dashboard"); // push user to dashboard after they login
    }
    if (nextProps.errors) {
      return ({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };
  onSubmit = e => {
    e.preventDefault();
    const userData = {
      username: this.state.username,
      password: this.state.password
    };
    this.props.loginUser(userData); // since we handle the redirect within our component, we don't need to pass in this.props.history as a parameter
  };
  
  render() {
    const { errors } = this.state;
    return (
      <div style={{background: 'linear-gradient(to right, #F37335, #FDC830)', height: '100vh'}}>
        <div className="container">
  <div className="row justify-content-center">
    <div className="col-md-9 col-lg-12 col-xl-10">
      <div className="card shadow-lg o-hidden border-0 my-5">
        <div className="card-body p-0" style={{boxShadow: 'none'}}>
          <div className="row">
            <div className="col-lg-6 d-none d-lg-flex">
              <div
                className="flex-grow-1 bg-login-image"
                style={{
                  backgroundImage: 'url("/assets/img/buddha.png")',
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: 'right'
                }}
              />
            </div>
            <div className="col-lg-6">
              <div className="p-5">
                <div className="text-center">
                  <h4 className="text-dark mb-4">Tripitaka Monk Login</h4>
                </div>
                <form className="user" noValidate onSubmit={this.onSubmit}>
                  <div className="form-group">
                  <input onChange={this.onChange} value={this.state.username} placeholder="Username"
                  error={errors.username} id="username" type="text"
                  className={classnames("form-control", { invalid: errors.username || errors.usernamenotfound })} />
                <span style={{ color: 'red', fontSize: 12 }}>
                  {/* Hello */}
                  {errors.username}
                  {errors.usernamenotfound}
                </span>
                  </div>
                  <div className="form-group">
                  <input
                  onChange={this.onChange} value={this.state.password} error={errors.password} placeholder="Password"
                  id="password" type="password" className={classnames("form-control", { invalid: errors.password || errors.passwordincorrect })} />
                <span style={{ color: 'red', fontSize: 12 }}>
                  {errors.password}
                  {errors.passwordincorrect}
                </span>
                  </div>
                  <button
                    className="btn btn-primary btn-block text-white btn-user"
                    type="submit"
                    style={{borderRadius: '2rem'}}
                  >
                    Login
                  </button>
                  <hr />
                  <p>
                    “Whatever words we utter should be chosen with care for
                    people will hear them and be influenced by them for good or
                    ill.” <br />
                    <span style={{ float: "right" }}>--Buddha</span>
                    <br />
                  </p>
                  <hr />
                  <button
                    className="btn btn-warning btn-block text-white btn-user"
                    type="submit"
                    style={{borderRadius: '2rem'}}
                    onClick={()=>{this.props.history.push('/')}}
                  >
                    Visit main page
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
      </div>
    );
  }
}
Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(
  mapStateToProps,
  { loginUser }
)(Login);