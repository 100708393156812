import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import "./ScreenEdit.css";
import Interweave from "interweave";

class EditScreen extends Component {
  constructor(props) {
    super(props);
    this.reppee = React.createRef();
  }
  state = {
    approver: "",
    publisher: "",
    loaded: false
  };
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  handleSubmitForm = e => {
    this.reppee.current.submitThisPlease();
  };

  componentDidMount() {
    document.title = "Verse Edit Screen";
    const stateobj = this.props.location.state;
    if (!this.props.auth) {
      this.props.history.push("dashboard");
    } else {
      if (!this.props.location.state) {
        this.props.history.push("/canon");
      } else {
        const usertype = this.props.auth.user.usertype.toLowerCase();
        console.log(stateobj);
        const something = async () => {
          try {
            const response = await axios.post(
              process.env.REACT_APP_API_ENDPOINT+
              "/" + usertype + "/getversebyid",
              {
                ...stateobj
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("jwtToken")}`
                }
              }
            );
            this.setState({
              verseNo: parseInt(stateobj.verseID),
              verseText: response.data.text,
              chapterName: stateobj.chapterName,
              approver: response.data.approver,
              publisher: response.data.publisher,
              loaded: true
            });
          } catch (error) {
            console.error(error);
          }
        };

        something();

        // axios.post('/'+usertype+'/getversebyid',posty,{headers: {Authorization: `Bearer ${localStorage.getItem("jwtToken")}`}})
        //   .then(response => {
        //     this.setState({
        //       verseNo: parseInt(stateobj.verseID),
        //       verseText: response.data.text,
        //       chapterName: stateobj.chapterName,
        //       approver: response.data.approver,
        //       publisher: response.data.publisher,
        //       loaded: true
        //     });
        //   })
        //   .catch(error => {
        //     console.log(error);
        //   }).finally(()=>{console.log(this.state)})
      }
    }
  }
  render() {
    const showButtonsAccordingly = () => {
      if (this.state.loaded) {
        return (
          <div
            className="buttons"
            style={{
              float: "right",
              marginRight: "4%",
              position: "relative",
              bottom: "0.3rem"
            }}
          >
            <button
              className="btn btn-danger btn-custom"
              style={{ borderRadius: "1rem" }}
              onClick={() => {
                this.props.history.goBack();
              }}
            >
              <i className="fa fa-remove" />
              &nbsp;Discard changes
            </button>
            <button
              id="initiator"
              style={{ borderRadius: "1rem", marginLeft: "3px" }}
              className="btn btn-success btn-custom"
              onClick={this.handleSubmitForm}
            >
              <i className="fa fa-check" />
              &nbsp;Submit
            </button>
          </div>
        );
      } else {
        return (
          <div style={{ float: "right", marginRight: "3%" }}>
            Loading, please wait...
          </div>
        );
      }
    };
    const { history } = this.props;
    const abc = () => {
      if (this.state.loaded) {
        return (
          <ReplicaCard
            forwardedDetails={this.state}
            another={this.props.location.state}
            seeker={history}
            access={this.props.auth.user}
            ref={this.reppee}
          />
        );
      }
    };
    return (
      <div>
        <nav
          className="navbar navbar-light navbar-expand-md sticky-top bg-light navigation-clean"
          style={{ borderBottom: "1px solid rgba(0,0,0,.1)" }}
        >
          <div className="container-fluid">
            <Link className="navbar-brand" to="/">
              Tripitaka
            </Link>
            <a
              className="btn btn-link btn-circle navbar-toggler collapsed"
              data-toggle="collapse"
              data-target="#navcol-1"
              aria-expanded="false"
              role="button"
              href="#about"
            >
              <i className="fa fa-angle-double-down animated" />
            </a>
            <li className="dropdown d-inline-block float-right ml-auto">
              <button
                onClick={this.props.history.goBack}
                style={{
                  border: "none",
                  color: "#c7a232",
                  padding: "0!important" /*optional*/,
                  cursor: "pointer",
                  background: "transparent"
                }}
              >
                <i
                  className="fa fa-chevron-circle-left"
                  aria-hidden="true"
                  style={{ fontSize: "xx-large" }}
                />
              </button>
            </li>{" "}
            &nbsp; &nbsp;
            <div
              className="navbar-collapse collapse"
              id="navcol-1"
              style={{}}
            ></div>
          </div>
        </nav>
        <div className="container-fluid">
          <div
            className="card aos-init aos-animate"
            style={{
              // height: "80vh",
              height: "auto",
              overflow: "hidden",
              width: "49.5%",
              display: "inline-block"
            }}
            data-aos="fade"
            data-aos-duration={1000}
          >
            <div className="card-body" data-aos="fade" data-aos-duration={800}>
              <h4
                className="card-title"
                style={{
                  textAlign: "center",
                  fontWeight: 500,
                  fontFamily: "Old English Text MT"
                }}
              >
                Chapter: {this.state.chapterName} | Verse {this.state.verseNo}
              </h4>
              <h6
                className="text-muted card-subtitle mb-2 hover-glow"
                style={{ textAlign: "center" }}
              >
                Pali (Published by {this.state.publisher})
              </h6>
              <div
                id="verseTextHere"
                className="card-text text-justify"
                style={{
                  cursor: "not-allowed",
                  padding: 5,
                  fontWeight: "bold",
                  height: "60vh",
                  overflowY: "auto",
                  fontSize: 14,
                  marginTop: "1rem"
                }}
              >
                <Interweave content={this.state.verseText} />
              </div>
            </div>
          </div>
          <div
            className="card aos-init aos-animate"
            style={{
              height: "auto",
              overflowY: "hidden",
              width: "49.5%",
              display: "inline-block"
            }}
            data-aos="fade"
            data-aos-duration={1000}
          >
            {abc()}
          </div>
        </div>
        {showButtonsAccordingly()}
      </div>
    );
  }
}

class ReplicaCard extends Component {
  state = {
    text: ""
  };

  componentDidMount() {
    let justTheText = document.getElementById("verseTextHere").innerText;
    this.setState({
      text: justTheText
    });
  }

  handleTextArea = e => {
    this.setState({
      text: e.target.value
    });
  };

  submitThisPlease = async () => {
    document.getElementById("initiator").setAttribute("disabled", "true");
    const postDetails = {
      canon_name: "atthakatha",
      basket_name: localStorage.getItem("basket_name"),
      book_name: this.props.another.bookName,
      chapter_name: this.props.forwardedDetails.chapterName,
      verse_no: this.props.forwardedDetails.verseNo.toString(),
      text: this.state.text,
      book_index: this.props.another.bookIndex,
      chapter_index: this.props.another.chapterIndex,
      script: localStorage.getItem("script")
    };
  

    const user = this.props.access.usertype.toLowerCase()
    const postURL = (user==="approver")? "/approver/commit" : "/publisher/submit"
    await axios
      .post(process.env.REACT_APP_API_ENDPOINT+postURL, postDetails, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`
        }
      })
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });

    await this.props.seeker.push({
      pathname: "/Submitted",
      state: {
        bookname: postDetails.book_name
      }
    });
  };

  render() {
    return (
      <div className="card-body" data-aos="fade" data-aos-duration={1600}>
        <h4
          className="card-title"
          style={{
            textAlign: "center",
            fontWeight: 500,
            fontFamily: "Old English Text MT"
          }}
        >
          Chapter {this.props.forwardedDetails.chapterName} | Verse{" "}
          {this.props.forwardedDetails.verseNo}
        </h4>
        <h6
          className="text-muted card-subtitle mb-2"
          style={{ textAlign: "center" }}
        >
          Pali (Type to modify)
        </h6>
        <textarea
          spellCheck="false"
          className="card-text"
          onChange={this.handleTextArea}
          value={this.state.text}
          style={{
            width: "100%",
            height: "58vh",
            fontWeight: "bold",
            background: "transparent",
            border: 0,
            fontSize: 14,
            marginTop: "1rem",
            resize: "none"
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps)(EditScreen);
connect(mapStateToProps)(ReplicaCard);

// const ShowTheLocationWithRouter = withRouter(EditScreen);
