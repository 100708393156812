import React, { Component } from 'react';
import { Link } from "react-router-dom";
class SkeletonHere extends Component {
    state = {
        basket: ""
      };
    
    
      
      componentDidMount(props) {
        document.body.style.background = "#f8f9fa";
        const mapping = {
          'vinayapitaka': 'Vinayapiṭaka',
          'suttapitaka': 'Suttapiṭaka',
          'abhidhammapitaka': 'Abhidhammapiṭaka',
          'anya': 'anya'
        }
        document.title = "Choose a basket";
        const basket = localStorage.getItem("basket_name");
        this.setState({ basket });
        if (!localStorage.getItem("script")) {
          localStorage.setItem("script", "roman")
        }
        if (!localStorage.getItem("canon_name")) {
          localStorage.setItem("canon_name","atthakatha")
        }
        if (localStorage.getItem("basket_name")) {
          const bask = localStorage.getItem("basket_name")

            this.props.history.push({
              pathname: '/Basket/'+ mapping[bask]
            });
          }  
      }
    
      setBasket = event => {
        // this.setState({ basket: basketName });
        localStorage.setItem("basket_name", event.target.dataset.basket);
        // let path = `basket`;
        // this.props.history.push(path);
      };

      notAvailable = () => {
        console.log("It has not been added yet")
      }

  render() {
     return (
      <div>
          <div>
          <nav className="navbar navbar-light navbar-expand bg-light navigation-clean" style={{background: 'radial-gradient(circle, rgb(255, 221, 64), rgb(162, 141, 42))', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 2px 5px 0px, rgba(0, 0, 0, 0.12) 0px 2px 10px 0px'}}>
          <div className="container">
            <Link className="navbar-brand" to="/">
              <span style={{color: 'white'}}>Tripitaka</span>
            </Link>
            </div>
        </nav>
        
  <section style={{minHeight: 'calc(100vh - 56px)'}} className="features-icons bg-light text-center" id="chooseBasket">
    <div className="container">
    <h6 data-aos="fade" data-aos-duration={500}>
    Aṭṭhakathā
      </h6>
      <h3 data-aos="fade" data-aos-duration={500}>
        Choose basket
      </h3>
      <div
        className="row"
        data-aos="fade"
        data-aos-duration={500}
        style={{ margin: "0px 10px" }}
      >
        <div className="col-lg-4" data-bs-hover-animate="pulse">
          <Link onClick={this.setBasket} data-basket="vinayapitaka" to='/Basket/Vinayapiṭaka'>
            <div data-basket="vinayapitaka" className="mx-auto features-icons-item mb-5 mb-lg-0 mb-lg-3 hover-glow">
              <div data-basket="vinayapitaka" className="d-flex features-icons-icon">
                <img data-basket="vinayapitaka"
                alt="vinayapitaka"
                  className="icon-layers m-auto text-primary img-rotate"
                  style={{ height: "4.5rem", borderRadius: "4.5rem" }}
                  src="https://upload.wikimedia.org/wikipedia/commons/d/df/Dharma_Wheel.svg"
                />
              </div>
              <h3 data-basket="vinayapitaka">Vinayapiṭaka</h3>
            </div>
          </Link>
        </div>
        <div className="col-lg-4" data-bs-hover-animate="pulse">
          <div className="button-as-link not-selectable" onClick={this.notAvailable} data-basket="suttapitaka">
            <div data-basket="suttapitaka" className="mx-auto features-icons-item mb-5 mb-lg-0 mb-lg-3 hover-glow unava-tooltip">
              <div data-basket="suttapitaka" className="d-flex features-icons-icon unava-tooltip">
                <img
                alt="suttapitaka"
                data-basket="suttapitaka"
                  className="icon-layers m-auto text-primary img-rotate unava-tooltip"
                  style={{ height: "4.5rem", borderRadius: "4.5rem" }}
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f1/Buddhism_dham_jak.svg/200px-Buddhism_dham_jak.svg.png"
                />
              </div>
              <span className="unava-tooltiptext">This portion of the Dhamma Texts will be made available for public view once it is scrutinised and approved by the ordained monks and scholars</span>
              <h3 data-basket="suttapitaka" style={{color: '#c7a232'}}>Suttapiṭaka</h3>
            </div>
          </div>
        </div>
        <div className="col-lg-4" data-bs-hover-animate="pulse">
          <div className="button-as-link not-selectable unava-tooltip" data-basket="abhidhammapitaka">
            <div data-basket="abhidhammapitaka" className="mx-auto features-icons-item mb-5 mb-lg-0 mb-lg-3 hover-glow unava-tooltip">
              <div data-basket="abhidhammapitaka" className="d-flex features-icons-icon unava-tooltip">
                <img data-basket="abhidhammapitaka"
                alt="abhidhammapitaka"
                  className="icon-layers m-auto text-primary img-rotate unava-tooltip"
                  style={{ height: "4.5rem", borderRadius: "4.5rem" }}
                  src="https://d2l0wy9lsui5uy.cloudfront.net/c/u/f67894297b6134a6b759b3a9ec15b6cb/2018/12/03145727/300px-Faith_Buddhism_Vajra.svg_.png"
                />
              </div>
              <span className="unava-tooltiptext">This portion of the Dhamma Texts will be made available for public view once it is scrutinised and approved by the ordained monks and scholars</span>
              <h3 data-basket="abhidhammapitaka" style={{color: '#c7a232'}}>Abhidhammapiṭaka</h3>
            </div>
          </div>
        </div>
        {/* <div className="col-lg-3 unava-tooltip" data-bs-hover-animate="pulse">
          <div className="button-as-link not-selectable unava-tooltip" data-basket="anya">
            <div data-basket="anya" className="mx-auto features-icons-item mb-5 mb-lg-0 mb-lg-3 hover-glow unava-tooltip">
              <div data-basket="anya" className="d-flex features-icons-icon unava-tooltip">
                <img
                data-basket="anya"
                alt="anya"
                  className="icon-layers m-auto text-primary img-rotate unava-tooltip"
                  style={{ height: "4.5rem", borderRadius: "4.5rem" }}
                  src="./assets/img/peace.png"
                />
              </div>
              <span className="unava-tooltiptext">This portion of the Dhamma Texts will be made available for public view once it is scrutinised and approved by the ordained monks and scholars</span>
              <h3 data-basket="anya" style={{color: '#c7a232'}}>Anya</h3>
            </div>
          </div>
        </div>
      */}
      </div>
    </div>
  </section>
  <section className="showcase" />
 </div>
     </div>    
       )
  }
}


export default SkeletonHere;
