// Action types defined in 'actions' folder, import them.
// It's necessary to provide action type so that the reducer acts upon the dispatch
import { GET_ERRORS } from "../actions/types";
// Reducers give new states to the store in the form of objects

const initialState = {};
export default function(state = initialState, action) { //Reducer functions take in initalState and action
  // Typical Reducer behavior - using switch cases for action types
  switch (action.type) {
    case GET_ERRORS:
      return action.payload; 
    default:
      return state; //Reducer returns a new state
  }
}