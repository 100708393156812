import React, { Component } from "react";
import { connect } from "react-redux";
import ProfileNavbar from "./ProfileNavbar";
import axios from "axios";

class MyVersesThatWereRejected extends Component {
  state = {
    loaded: false,
    rejloaded: false,
    rejectedVerses: []
  };

  
  componentDidMount() {
    window.scrollTo(0, 0);

    axios.get(process.env.REACT_APP_API_ENDPOINT+"/publisher/getRejected", { headers: {"Authorization" : `Bearer ${localStorage.getItem("jwtToken")}`} }).then(response => {
      console.log(response)
        this.setState({ rejectedVerses: response.data, rejloaded: true });
      })
      .catch(error => {
        console.log(error);
      });
  }

  clickHandler = e => {
    console.log(e.target);
  };


  render() {
    const rejTable = () => {
      if (this.props.auth.user.usertype.toLowerCase() !== "publisher") {
        console.log("You are not authorized");
      } else {
        if (this.state.rejloaded) {
          if (this.state.rejectedVerses.length === 0) {
            return <tr>
            <td colSpan='6'>No rejected verse found.</td>
            </tr>
          } else {
            return this.state.rejectedVerses.map((currEl, i) => {
              return (
                <tr key={i}>
                  <th scope="row">{i + 1}</th>
                  <td><span>{currEl.entry_details.book_name}</span></td> 
                  <td><span>{currEl.entry_details.chapter_name}</span></td>
                  <td><span>{currEl.entry_details.verse_details.verse_no}</span></td>
                  <td><p className="line-clamp line-clamp-1">{currEl.entry_details.verse_details.text}</p></td>
                  <td><span>{currEl.rejecter_name}</span></td>
                  <td>
                    <button
                      onClick={() => {
                            this.props.history.push({
                              pathname: '/Profile/Rejected/View',
                              state: {currEl}
                            })
                      }}
                      className="btn btn-success"
                      style={{ textTransform: "none" }}
                    >
                      View
                    </button>
                  </td>
                </tr>
              );
            });
          }
        } else {
          return "Please wait, loading...";
        }
      }
    };

    return (
      <div>
        <ProfileNavbar />
        <section
          className="testimonials text-center bg-light"
          style={{ minHeight: "calc(100vh - 56px)" }}
        >
          <div className="container">
            <h5 className="mb-2">
              Following verses submitted by you have been rejected
            </h5>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Book</th>
                  <th scope="col">Chapter</th>
                  <th scope="col">Verse</th>
                  <th scope="col">Text</th>
                  <th scope="col">Rejected by</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>{rejTable()}</tbody>
            </table>
            {/* <div className="row">{rejectedQuery()}</div> */}
          </div>
        </section>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps)(MyVersesThatWereRejected);
