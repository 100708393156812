// Action types defined in 'actions' folder, import them.
// It's necessary to provide action type so that the reducer acts upon the dispatch
import { SET_CURRENT_USER,USER_LOADING } from "../actions/types";
const isEmpty = require("is-empty");
// Provide an initial state to the Reducer function

const initialState = {
    isAuthenticated: false,
    user: {},
    loading: false
};

export default function(state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state, //copies everything from state (=initial state)
                // but makes the following changes:
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload
            };
        case USER_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
}