import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import "./verses.css"

// A simple component that shows the pathname of the current location
class Submitted extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  takeMeToChaptersAgain = e => {
    const mapping = {
      'vinayapitaka': 'Vinayapiṭaka',
      'suttapitaka': 'Suttapiṭaka',
      'abhidhammapitaka': 'Abhidhammapiṭaka',
      'anya': 'anya'
    }

    this.props.history.push({
      pathname: '/Basket/'+mapping[localStorage.getItem("basket_name")]+'/Book/'+this.props.location.state.bookname+'/'
    });
  };

  seeStatus = () => {
    this.props.history.push("/Profile");
  };

  render() {
    const appropriateRender = () => {
      if (this.props.auth.user.usertype.toLowerCase() === "approver") {
        return <h1>Your verse has been added to the Blockchain.</h1>;
      } else {
        return <h1>Your change has been submitted for approval.</h1>;
      }
    };
    return (
      <div>
        <nav className="navbar navbar-light navbar-expand-md fixed-top bg-light navigation-clean">
          <div className="container">
            <Link className="navbar-brand" to="/">
              Tripitaka
            </Link>
            <button
              data-toggle="collapse"
              className="navbar-toggler"
              data-target="#navcol-1"
            />
            <div className="collapse navbar-collapse" id="navcol-1">
              {/* <a class="btn btn-primary ml-auto" role="button" href="#">Know more</a> */}
            </div>
          </div>
        </nav>
        <div
          className="container text-center"
          style={{ height: "80vh", maxHeight: "80vh" }}
        >
          <img
            alt="tick"
            src="./assets/img/tick.gif"
            style={{ filter: "hue-rotate(319deg) invert(0%)", height: 271 }}
          />
          {appropriateRender()}
          <div
            role="group"
            className="btn-group"
            style={{ filter: "hue-rotate(203deg)" }}
          >
            <button
              className="btn btn-primary"
              onClick={this.takeMeToChaptersAgain}
              type="button"
            >
              View More Chapters
            </button>
            <button
              onClick={this.seeStatus}
              className="btn btn-primary"
              type="button"
            >
              See the status of my submitted verses
            </button>
          </div>
        </div>
        {/* <footer className="footer bg-light">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 my-auto h-100 text-center text-lg-left">
          <ul className="list-inline mb-2">
            <li className="list-inline-item">
              <a href="#">About</a>
            </li>
            <li className="list-inline-item">
              <span>⋅</span>
            </li>
            <li className="list-inline-item">
              <a href="#">Contact</a>
            </li>
            <li className="list-inline-item">
              <span>⋅</span>
            </li>
          </ul>
          <p className="text-muted small mb-4 mb-lg-0">
            © DhammaBlock 2019. All Rights Reserved.
          </p>
        </div>
        <div className="col-lg-6 my-auto h-100 text-center text-lg-right">
          <ul className="list-inline mb-0">
            <li className="list-inline-item">
              <a href="#">
                <i className="fa fa-facebook fa-2x fa-fw" />
              </a>
            </li>
            <li className="list-inline-item">
              <a href="#">
                <i className="fa fa-twitter fa-2x fa-fw" />
              </a>
            </li>
            <li className="list-inline-item">
              <a href="#">
                <i className="fa fa-instagram fa-2x fa-fw" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer> */}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps)(Submitted);
// Create a new component that is "connected" (to borrow redux
// terminology) to the router.
