import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from "react-redux";
import "./ScreenEdit.css"
import Interweave from "interweave"
import ProfileNavbar from './ProfileNavbar';
import AOS from 'aos'
class ViewVerse extends Component{
  constructor(props){
    super(props)
    this.reppee = React.createRef();
    AOS.init();

  }
  state = {
    approver: '',
    publisher: '',
    loaded: false,
  }
  static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    handleSubmitForm = (e) => {
      this.reppee.current.submitThisPlease();
    }

    componentDidMount(){
        if(!this.props.auth){
            this.props.history.push('dashboard');
        }
        else
        {
          if(!this.props.location.state){
            this.props.history.push('/canon')
          }
      }
    }
    render(){
      const stateobj = (this.props.location.state.currentView)
      console.log(stateobj)
        return(
            <div>
            <ProfileNavbar />
            <div className="container-fluid" style={{textAlign: 'center', paddingTop: '60px'}}>
              <div
                className="card"
                style={{
                  // height: "80vh",
                  height: 'auto',
                  overflow: "hidden",
                  width: "70%",
                  display: "inline-block"
                }}
                // data-aos="fade"
                // data-aos-duration={1000}
              >
                <div className="card-body" 
                // data-aos="fade" data-aos-duration={800}
                >
                  <h4
                    className="card-title"
                    style={{
                      textAlign: "center",
                      fontWeight: 500,
                      fontFamily: "Old English Text MT"
                    }}
                  >
                    Chapter: {stateobj.entry_details.chapter_name} | Verse {stateobj.entry_details.verse_details.verse_no}
                  </h4>
                  <h6
                    className="text-muted card-subtitle mb-2 hover-glow"
                    style={{ textAlign: "center" }}
                  >
                    {/* Pali (Published by {stateobj.publisher_details.publisher_name}) | Publish Time: {stateobj.approval_time} */}
                    <span style={{color: 'brown'}}>Pali (Approved by {stateobj.approver_name}) </span> | <span style={{color: 'brown'}}>Publish Time {stateobj.approval_time}</span>

                  </h6>
                  <div
                    id="verseTextHere"
                    className="card-text text-justify"
                    style={{
                      padding: 5,
                      fontWeight: "bold",
                    //   height: "60vh",
                    height: 'calc(100vh - 200px)',
                      overflowY: "auto",
                      fontSize: 14,
                      marginTop: '1rem'
                    }}
                  >
                  <Interweave content=
                    {stateobj.entry_details.verse_details.text} />
                  </div>
                </div>
              </div>
              </div>
          </div>          
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});
export default connect(mapStateToProps)(ViewVerse);

// const ShowTheLocationWithRouter = withRouter(EditScreen);