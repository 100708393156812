import React, { Component } from 'react'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {Link, withRouter} from "react-router-dom";
import { logoutUser } from "../../../../actions/authActions"

class ProfileNavbar extends Component{
    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser();
      };

      passwordChangeHandler = () => {
        this.props.history.push('/PasswordChange')
      }

      backHandler = () => {
        this.props.history.goBack()
      }

    render(){
        return(
            <nav className="navbar navbar-light navbar-expand-lg fixed-top bg-white clean-navbar" style={{background: 'radial-gradient(circle, rgb(255, 221, 64), rgb(162, 141, 42))', boxShadow: '0 1px 1px 0px rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)'}}>
            <div className="container">
              <div className="navbar-brand logo">
                <Link to='/' style={{color: 'white'}}>
                Tripitaka
                </Link>
                <button onClick={this.backHandler} style={{border: "none",color: "black",cursor: "pointer",background: "transparent", marginLeft: '15px', position: 'relative', top: '3px'}}>
                <i className="fa fa-chevron-circle-left" aria-hidden="true" style={{ fontSize: "xx-large" }}/>
                </button>
              </div>
              
              <button
                data-toggle="collapse"
                data-target="#navcol-1"
                className="navbar-toggler"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse" id="navcol-1">
              
                <ul className="nav navbar-nav ml-auto">
                  <li role="presentation" className="nav-item">
                    {/* <a className="nav-link active text-center" >
                      Home
                    </a> */}
                    <button onClick={()=>{this.props.history.push('/')}} className="nav-link mx-auto button-as-link" style={{color: 'white'}} >
                      Home
                    </button>
                  </li>
                  <li role="presentation" className="nav-item">
                    <button onClick={this.passwordChangeHandler} className="nav-link mx-auto text-center button-as-link" style={{color: 'white'}}>
                      Change Password
                    </button>
                  </li>
                  <li role="presentation" className="nav-item">
                    <button style={{background: 'none', border: 'none', cursor: 'pointer'}} className="nav-link mx-auto" onClick={this.onLogoutClick}>
                      <span style={{color: 'white'}}>Log Out</span>
                    </button>
                  </li>
                  </ul>
              </div>
            </div>
          </nav>
        )
    }
}

ProfileNavbar.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
  };
  
  
  const mapStateToProps = state => ({
    auth: state.auth
  });
  export default withRouter(connect(mapStateToProps, {logoutUser})(ProfileNavbar));