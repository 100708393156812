import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import "./verses.css";
import Interweave from "interweave";
import unidecode from "unidecode";
import AOS from 'aos'; 


class Verses extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      beginning: 1,
      canonName: "atthakatha",
      chapterName: "",
      bookName: this.props.match.params.BookName,
      bookIndex: "",
      chapterIndex: "",
      basketName: this.props.match.params.BasketName,
      verses: [],
      listOfChapters: [],
      loaded: false,
      loadthearrows: false
    };
    AOS.init();

  }


  componentDidMount(prevState, newState) {
    // const { match, location } = this.props;
    // let current = match.params;
    if(!localStorage.getItem("canon_name")){
      localStorage.setItem("canon_name","atthakatha")
    }
    if(!localStorage.getItem("script")){
      localStorage.setItem("script","roman")
    }
    this.rerenderAgain();
    window.scrollTo(0,0)
  }

  componentDidUpdate(prevProps, prevState) {
    document.title = this.props.match.params.ChapterName + " - Tripitaka";
    if (
      prevProps.match.params.ChapterName !== this.props.match.params.ChapterName
    ) {
      this.setState({
        loaded: false
      })
      // console.log("Chapter changed");
      this.rerenderAgain();
      window.scrollTo(0, 0);
      AOS.refresh(); 

    }

  }

  rerenderAgain = async () => {
    this.setState({
      chapterName: this.props.match.params.ChapterName
    });
    // Simple basket name text to be sent as request
      const baskeet = unidecode(this.state.basketName).toLowerCase()
      
      let response = await axios.get(process.env.REACT_APP_API_ENDPOINT+"/viewer/getmap?school=atthakatha&basket="+baskeet);
      let bookObj = response.data.books;

      // Finding Book Index from Book Object
      let indexOfBook = await bookObj.findIndex(
        i => i.name === this.state.bookName
      ).toString();
      const bookname = this.props.match.params.BookName;


      var chapterObj = await bookObj.filter(async bookie => {
        return (await bookie.name) === bookname;
      });

      // Finding Chapter Index from Chapter Object
      const chapterArray = chapterObj[0].chapters;
      let indexOfChapter = await chapterArray
        .indexOf(this.state.chapterName)
        .toString();
      this.setState({
        listOfChapters: chapterArray,
        bookIndex: indexOfBook,
        chapterIndex: indexOfChapter,
        loadthearrows: true
      });

      const abc = async () => {
        let postParams = {
          language: "pali",
          script: localStorage.getItem("script"),
          school: "atthakatha",
          basket: baskeet,
          bookIndex: this.state.bookIndex,
          chapterIndex: this.state.chapterIndex
          // bookIndex: this.state.bookIndex,
          // chapterIndex: this.state.chapterIndex
        };
        if(this.props.auth.isAuthenticated===false){
          let response = await axios.post(process.env.REACT_APP_API_ENDPOINT+"/viewer/getversebychapter",postParams);
          console.log(response);
        this.setState({ verses: response.data, loaded: true });
        }
        else if(this.props.auth.isAuthenticated===true){
          if(this.props.auth.user.usertype.toLowerCase()==="publisher"){
            let response = await axios.post(process.env.REACT_APP_API_ENDPOINT+"/publisher/getversebychapter",postParams, { headers: {"Authorization" : `Bearer ${localStorage.getItem("jwtToken")}`} });
            // console.log(response);
        this.setState({ verses: response.data, loaded: true });
          }
          else if(this.props.auth.user.usertype.toLowerCase()==="approver"){
            let response = await axios.post(process.env.REACT_APP_API_ENDPOINT+"/approver/getversebychapter",postParams, { headers: {"Authorization" : `Bearer ${localStorage.getItem("jwtToken")}`} });
            // console.log(response);
        this.setState({ verses: response.data, loaded: true });
          }
        }

        
      };
      abc();
    }

  papaFunction = async () => {
    await this.getTheChapters();
    await this.getDetails();
  };

  getDetails = async () => {
    const script = localStorage.getItem("script");
    const school = localStorage.getItem("canon_name");
    const basket = localStorage.getItem("basket_name");
    let postParams = {
      language: "pali",
      script: script,
      school: school,
      basket: basket,
      bookIndex: this.props.location.state.bookIndex.toString(),
      chapterIndex: this.props.location.state.currentChapterIndex.toString()
    };
    if (this.props.auth.isAuthenticated) {
      console.log("You're logged in");
    } else {
      console.log(postParams);
      let response = await axios.post(process.env.REACT_APP_API_ENDPOINT+"/viewer/getversebychapter", postParams);
      this.setState({ verses: response.data, loaded: true });
    }
  };

  nextPageNav = async () => {
    const currentChapterIndex = this.state.listOfChapters.indexOf(
      this.state.chapterName
    );
    await this.props.history.push({
      pathname: `../${this.state.listOfChapters[currentChapterIndex + 1]}/`
    });
    await this.forceUpdate();
  };

  chapterArrowCheck = () => {
    if (this.state.loadthearrows) {
      const currentChapterIndex = this.state.listOfChapters.indexOf(
        this.state.chapterName
      );
      if (currentChapterIndex === 0 && this.state.listOfChapters.length > 1) {
        return (
          // `../${this.state.listOfChapters[currentChapterIndex+1]}/`
          <button
            onClick={this.nextPageNav}
            className="nav-arrow"
            style={{ border: 0, left: "auto", right: 0 }}
          >
            <i
              className="fa fa-chevron-right"
              style={{ color: "white", lineHeight: "3.5" }}
              aria-hidden="true"
            />
          </button>
        );
      } else if (
        currentChapterIndex === this.state.listOfChapters.length - 1 &&
        this.state.listOfChapters.length !== 1
      ) {
        return (
          <button
            onClick={() => {
              this.props.history.push(
                `../${this.state.listOfChapters[currentChapterIndex - 1]}/`
              );
            }}
            className="nav-arrow"
            style={{ border: 0 }}
            alt="Previous Chapter"
          >
            <i
              className="fa fa-chevron-left"
              style={{ color: "white", lineHeight: "3.5" }}
              aria-hidden="true"
            />
          </button>
        );
      } else {
        return (
          <div>
            <button
              onClick={() => {
                this.props.history.push(
                  `../${this.state.listOfChapters[currentChapterIndex - 1]}/`
                );
              }}
              className="nav-arrow"
              style={{ border: 0 }}
              alt="Previous Chapter"
            >
              <i
                className="fa fa-chevron-left"
                style={{ color: "white", lineHeight: "3.5" }}
                aria-hidden="true"
              />
            </button>
            <button
              onClick={() => {
                this.props.history.push(
                  `../${this.state.listOfChapters[currentChapterIndex + 1]}/`
                );
              }}
              className="nav-arrow"
              style={{ border: 0, left: "auto", right: 0 }}
              alt="Next Chapter"
            >
              <i
                className="fa fa-chevron-right"
                style={{ color: "white", lineHeight: "3.5" }}
                aria-hidden="true"
              />
            </button>
          </div>
        );
      }
    }
  };

  editGo = async event => {
    // let path = '/Edit/' + + '/' + this.state.chapterName + '/' + event.target.dataset.verse;
    try {
      let actualVerseID = event.target.dataset.verse.toString()
      let response = await axios.post(process.env.REACT_APP_API_ENDPOINT+"/viewer/getindices", {
        school: "atthakatha",
        basket: "vinayapitaka",
        book: this.props.match.params.BookName,
        chapter: this.props.match.params.ChapterName
      });
      const indexOfBook = response.data.book_index.toString()
      const indexOfChapter = response.data.chapter_index.toString()
      const stato = {
        language: "pali",
        script: localStorage.getItem("script"),
        school: this.state.canonName,
        basket: unidecode(this.state.basketName).toLowerCase(),
        bookIndex: indexOfBook,
        chapterIndex: indexOfChapter,
        verseID: actualVerseID,
        chapterName: this.state.chapterName,
        bookName: this.state.bookName
      }

      this.props.history.push({
      pathname: "/Edit",
      state: stato
    });
    } catch (error) {
      console.error(error);
    }
    // this.props.history.push({
    //   pathname: "/Edit",
    //   state: {
    //     language: "pali",
    //     script: localStorage.getItem("script"),
    //     school: this.state.canonName,
    //     basket: this.state.basketName,
    //     bookName: this.state.bookName,
    //     chapterName: this.state.chapterName,
    //     verseID: event.target.dataset.verse
    //   }
    // });
  };

  render() {
    const additionalInfoCheck = currEl => {
      let modalVar = "myModal" + currEl.verseID;
      if (currEl.status === "published") {
        return (
          <sup style={{ float: "right" }}>
            <Link
              to="#"
              data-toggle="modal"
              data-target={"#" + modalVar}
              style={{
                backgroundColor: "rgb(243, 201, 75)",
                padding: "4px 8px",
                borderRadius: "10px",
                color: "white",
                position: "relative",
                top: "7px",
                left: "15px"
              }}
            >
              Additional info
            </Link>
          </sup>
        );
      }
    };
    const labelCheck = currEl => {

      if(this.props.auth.isAuthenticated){
        if (
          this.props.auth.user.usertype.toLowerCase() === "publisher" ||
          this.props.auth.user.usertype.toLowerCase() === "approver"
        ) {
          if (currEl.status === "published") {
            return <p className="labelApproved">Published</p>;
          } else if (currEl.status === "unpublished") {
            return <p className="labelNotApproved">Yet to be published</p>;
          }
        }
      } else {
        // return null;
        return <p className="labelNotApproved" style={{visibility: 'hidden'}}>Published</p>;
      }
    };
    const { user } = this.props.auth;
    const abc = verseNO => {
      if (user.usertype) {
        return (
          <div style={{ textAlign: "right" }}>
            <button
              className="btn btn-sm btn-warning"
              style={{
                // float: "right",
                color: "white",
                backgroundColor: "#856404"
                // position: "relative",
                // bottom: "30px",
                // right: "30px"
              }}
              data-verse={verseNO}
              data-chapter={this.state.chapterName}
              onClick={this.editGo}
            >
              <i
                className="fa fa-pencil"
                data-verse={verseNO}
                aria-hidden="true"
              ></i>
            </button>
          </div>
        );
      }
    };
    const showLoader = () => {
      if (!this.state.loaded) {
        return (
          <div className="loader" style={{ height: "50vh" }}>
            <svg className="circular" viewBox="25 25 50 50">
              <circle
                className="path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                strokeWidth="2"
                strokeMiterlimit="10"
              />
            </svg>
          </div>
        );
      } else {
        return (
          <div
            className="container"
            // style={{ paddingTop: 30 }}
          >
            {this.chapterArrowCheck()}
            {/* {this.switchModeCheck()} */}
            <h3 data-aos="fade" data-aos-duration={500}>
              {/* {current.ChapterName} */}
              {this.state.chapterName}
            </h3>{" "}
            <div className="container" style={{ paddingTop: 10 }}>
              {/* yeha */}
              <button id="backButtonForSmallScreens" className="switch-mode"
                onClick={() => {
                  this.props.history.push({
                  pathname: "/Basket/"+this.state.basketName+"/Book/"+this.state.bookName+"/"
                  }
                    );
                }}
                style={{
                  border: "none",
                  color: "black",
                  padding: "0!important" /*optional*/,
                  cursor: "pointer",
                  background: "transparent"
                }}
              >
                <i
                  className="fa fa-chevron-circle-left"
                  aria-hidden="true"
                  style={{ fontSize: "xx-large" }}
                />
              </button>
            
              <div className="row" style={{ margin: "auto" }}>
                <div className="col">
                  <ol
                    className="versesList"
                    style={{
                      counterReset: "my-awesome-counter " + this.state.beginning
                    }}
                  >
                    <div style={{width: '-webkit-fill-available'}}>
                      {this.state.verses.map((currEl, i) => {
                        let modalVar = "myModal" + currEl.verseID;
                        // console.log(currEl.status==="unpublished")
                        let available = (currEl.status==="unpublished" && !this.props.auth.isAuthenticated)?"unavailable-verse":null
                        return (
                          <div className="list" key={i}>
                            <article style={{ marginBottom: "15px" }}>
                              {abc(currEl.verseID)}

                              <div className="outsideVerseNumber">
                                <div>{currEl.verseID==="0"?"":currEl.verseID}</div>
                                {/* Perform Label Check here */}
                                {labelCheck(currEl)}
                              </div>
                              <div
                                className="card"
                                id={`verse${currEl.verseID}`}
                                style={{
                                  boxShadow:
                                    "0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)"
                                }}
                              >
                                <div
                                  className="card-body text-justify"
                                  style={{ boxShadow: "none" }}
                                >
                                  <div className="insideVerseNumber">
                                    {/* Perform Label Check here */}
                                    {labelCheck(currEl)}
                                    <span >{currEl.verseID==="0"?"":currEl.verseID}</span>
                                  </div>
                                  <h6 className={`card-title verseContent ${available}`}>
                                    <Interweave content={currEl.text}/>
                                  </h6>
                                  {additionalInfoCheck(currEl)}
                                </div>
                              </div>
                            </article>
                            <Modal
                              identifier={modalVar}
                              chapter={this.state.chapterName}
                              verse={currEl.verseID}
                            >
                              <p>
                                Published by{" "}
                                <strong>
                                  {currEl.publisher}
                                  {/* {currEl.versions.slice(-1)[0].publisher} */}
                                </strong>
                              </p>
                              <p>
                                Approved by{" "}
                                <strong>
                                  {currEl.approver}
                                  {/* {currEl.versions.slice(-1)[0].approver} */}
                                </strong>
                              </p>
                            </Modal>
                          </div>
                        );
                      })}
                      {renderFinish()}
                    </div>
                  </ol>
                </div>
              </div>{" "}
            </div>{" "}
          </div>
        );
      }
    };
    const renderFinish = () => {
      if (this.state.loaded) {
        return <i>End of Chapter</i>;
      }
    };
    const chaptersRender = () => {
      return this.state.listOfChapters.map((currEl, i) => {
        return (
          <Link
            role="presentation"
            className="dropdown-item"
            to={`../${currEl}/`}
            key={i}
          >
            {currEl}
          </Link>
        );
      });
    };

    return (
      <div>
        <nav
          id="fixednavbar"
          // data-aos="fade"
          // data-aos-duration={500}
          className="navbar navbar-light navbar-expand-md sticky-top navigation-clean"
          style={{
            borderBottom: "1px solid rgba(0,0,0,.1)",
            background:
              "radial-gradient(circle, rgb(255, 221, 64), rgb(162, 141, 42))",
            boxShadow:
              "0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)"
          }}
        >
          <div className="container">
            <Link className="navbar-brand" to="/" style={{ color: "white" }}>
              Tripitaka
            </Link>
            <li id="backButtonVerses" className="dropdown d-inline-block float-right ml-auto">
              <button
                onClick={() => {
                  this.props.history.push({
                  pathname: "/Basket/"+this.state.basketName+"/Book/"+this.state.bookName+"/"
                  }
                    );
                }}
                style={{
                  border: "none",
                  color: "black",
                  padding: "0!important" /*optional*/,
                  cursor: "pointer",
                  background: "transparent"
                }}
              >
                <i
                  className="fa fa-chevron-circle-left"
                  aria-hidden="true"
                  style={{ fontSize: "xx-large" }}
                />
              </button>
            </li>
            <li
              className="dropdown d-inline-block float-right ml-auto"
              id="changeChapterDropDown"
            >
              <div
                data-toggle="dropdown"
                aria-expanded="false"
                className="dropdown-toggle border rounded d-inline-block float-right"
                href="#"
                style={{
                  fontSize: "12px",
                  cursor: "pointer",
                  // marginRight: 27,
                  padding: 7,
                  textDecoration: "none" /* background: '#ffc107', */,
                  background: "radial-gradient(circle, #ffdd40, #a28d2a)",
                  color: "black"
                }}
              >
                Change Chapter
              </div>
              <div role="menu" className="dropdown-menu">
                {chaptersRender()}
                {/* {console.log(this.props.location.state.chaptersList)} */}
              </div>
            </li>
            <li className="dropdown d-inline-block float-right ml-auto">
              <div
                data-toggle="dropdown"
                aria-expanded="false"
                className="dropdown-toggle border rounded d-inline-block float-right"
                href="#"
                style={{
                  fontSize: "12px",
                  cursor: "pointer",
                  marginRight: "1rem",
                  padding: 7,
                  textDecoration: "none",
                  background: "radial-gradient(circle, #ffdd40, #a28d2a)",
                  color: "black"
                }}
              >
                Go to verse
              </div>
              <div
                role="menu"
                id="verselinks"
                className="dropdown-menu"
                // style={{ left: "-1rem!important" }}
                style={{
                  left: "-70px",
                  maxHeight: "400px",
                  overflow: "auto",
                  textAlign: "center"
                }}
              >
                {this.state.verses.map((currEl, i) => {
                  return (
                    <button
                      // href={`${this.props.location.pathname}#verse${currEl.verseID}`}
                      key={i}
                      role="presentation"
                      className="dropdown-item button-as-link"
                      onClick={() => {
                        // let sT = document.getElementById('verse'+currEl.verseID).offsetTop + 70
                        let sT = document.getElementById('verse'+currEl.verseID).offsetTop + 70
                        window.scrollTo(0,sT);
                      }}
                    >
                      {currEl.verseID}
                    </button>
                  );
                })}
              </div>
            </li>
            <div className="collapse navbar-collapse show" id="navcol-1" />
          </div>
        </nav>
        <section
          className="features-icons bg-light text-center"
          style={{ paddingTop: "2rem", minHeight: "calc(100vh - 58px)" }}
        >
          {showLoader()}
        </section>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps, null, null, { pure: false })(Verses);

class Modal extends Component {
  render() {
    return (
      <div className="modal fade" id={this.props.identifier} role="dialog">
        <div className="modal-dialog">
          {/* Modal content*/}
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                {this.props.chapter} Verse {this.props.verse}
              </h4>
              <button type="button" className="close" data-dismiss="modal">
                ×
              </button>
            </div>
            <div className="modal-body text-justify">{this.props.children}</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                // onClick={}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export default connect(mapStateToProps)(Verses);
// // Create a new component that is "connected" (to borrow redux
// // terminology) to the router.
// const ShowTheLocationWithRouter = withRouter(Verses);
