import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
class ApproveSplitScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verseDetails: this.props.location.state.verse_info,
      loadFirstCard: false
    };
    this.reppee = React.createRef();
  }

  componentDidMount() {
    if (!this.props.location) {
      this.props.history.push("/Profile/Review");
    }
  }

  handleSubmitForm = e => {
    this.reppee.current.handleApproval();
  };

  handleRejectForm = e => {
    this.reppee.current.handleRejection();
  };

  verseDetailHandler = something => {
    this.setState({
      verseDetails: something,
      loadFirstCard: true
    });
  };

  renderFirst = () => {
    const obby = this.props.location.state
    if (this.state.loadFirstCard) {
      const objy = {
        language: obby.verse_info.entry_details.language,
        script:obby.verse_info.entry_details.verse_details.script ,
        school: obby.verse_info.entry_details.canon_name,
        basket: obby.verse_info.entry_details.basket_name,
        bookIndex: obby.verse_info.entry_details.book_index,
        chapterIndex: obby.verse_info.entry_details.chapter_index,
        verseID: obby.verse_info.entry_details.verse_details.verse_no
      };
      return <FirstCard giveDetails={objy} />;
    } else {
      return <div>Loading...</div>;
    }
  };

  render() {
    return (
      <div>
        <nav
          className="navbar navbar-light navbar-expand-md sticky-top bg-light navigation-clean"
          style={{ borderBottom: "1px solid rgba(0,0,0,.1)" }}
        >
          <div className="container-fluid">
            <Link className="navbar-brand" to="/">
              Tripitaka
            </Link>
            <a
              className="btn btn-link btn-circle navbar-toggler collapsed"
              data-toggle="collapse"
              data-target="#navcol-1"
              aria-expanded="false"
              role="button"
              href="#about"
            >
              <i className="fa fa-angle-double-down animated" />
            </a>
            <li className="dropdown d-inline-block float-right ml-auto">
              <button
                onClick={this.props.history.goBack}
                style={{
                  border: "none",
                  color: "#c7a232",
                  padding: "0!important" /*optional*/,
                  cursor: "pointer",
                  background: "transparent"
                }}
              >
                <i
                  className="fa fa-chevron-circle-left"
                  aria-hidden="true"
                  style={{ fontSize: "xx-large" }}
                />
              </button>
            </li>{" "}
            &nbsp; &nbsp;
            <div
              className="navbar-collapse collapse"
              id="navcol-1"
              style={{}}
            ></div>
          </div>
        </nav>

        <div className="container-fluid">
          {this.renderFirst()}
          <SecondCard
            state={this.props.location.state}
            history={this.props.history}
            getDetails={this.verseDetailHandler}
            // auth={this.props.auth}
            ref={this.reppee}
          />
        </div>
        <div
          className="buttons"
          style={{
            float: "right",
            marginRight: "4%",
            position: "relative",
            bottom: "0.3rem"
          }}
        >
          <button
            className="btn btn-danger btn-custom"
            style={{ borderRadius: "1rem" }}
            onClick={this.handleRejectForm}
          >
            <i className="fa fa-remove" />
            &nbsp;Reject
          </button>
          <button
            id="initiator"
            style={{ borderRadius: "1rem", marginLeft: "3px" }}
            className="btn btn-success btn-custom"
            onClick={this.handleSubmitForm}
          >
            <i className="fa fa-check" />
            &nbsp;Approve
          </button>
        </div>
      </div>
    );
  }
}

class FirstCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentVerseDetails: {},
      loadFirstsText: false
    };
  }

  populateFirst = async () => {
    const queryParam = this.props.giveDetails;
    try {
      const response = await axios.post(process.env.REACT_APP_API_ENDPOINT+"/approver/getversebyid", queryParam, {
        headers: { Authorization: `Bearer ${localStorage.getItem("jwtToken")}` }
      });
      console.log('rs',response.data)
      this.setState({
        currentVerseDetails: response.data,
        loadFirstsText: true
      });
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    this.populateFirst();

  }

  loadUpperThingy = () => {
    if(this.state.loadFirstsText){
      return(
        "Chapter " + document.getElementById("thisChapterName").innerText +
        "| Verse No. " + this.props.giveDetails.verseID
      )
    }
  }

  loadedFromWhat = () => {
    // console.log('loadedfromwhat',this.state.currentVerseDetails.publisher)
    if(this.state.currentVerseDetails.publisher)
    {
      return(
        "Pali (Published by " + this.state.currentVerseDetails.publisher + ")"
      )
    }
    else{
      return(
        "Pali (Published by Emertech)"
      )
    }
  }

  render() {
    return (
      <div
        className="card aos-init aos-animate"
        style={{
          overflow: "hidden",
          width: "49.5%",
          height: "80vh",
          display: "inline-block"
        }}
        data-aos="fade"
        data-aos-duration={1000}
      >
        <div className="card-body" data-aos="fade" data-aos-duration={800} style={{height: 'inherit'}}>
          <h4
            className="card-title"
            style={{
              textAlign: "center",
              fontWeight: 500,
              fontFamily: "Old English Text MT"
            }}
          >
            {this.loadUpperThingy()}

            {/* Chapter {this.props.giveDetails.entry_details.chapter_name} Verse{" "}
            {this.props.giveDetails.entry_details.verse_details.verse_no} */}
          </h4>
          <h6
            className="text-muted card-subtitle mb-2 hover-glow"
            style={{ textAlign: "center" }}
          >
            {/* Pali (Published by {this.state.loadFirstsText?this.state.currentVerseDetails.publisher:null}) */}
            {this.loadedFromWhat()}
          </h6>
          <p
            className="card-text text-justify"
            // style={{
            //   padding: 5,
            //   fontWeight: "bold",
            //   height: "100vh",
            //   overflowY: "auto",
            //   fontSize: 14,
            //   marginTop: 30
            // }}
            style={{
              padding: '5px',fontWeight: 'bold',height: '33rem',overflowY: 'auto',fontSize: '14px',marginTop: '30px',paddingBottom: '28px',
            }}
          >
            {this.state.loadFirstsText?this.state.currentVerseDetails.text:null}
          </p>
        </div>
      </div>
    );
  }
}

class SecondCard extends Component {
  state = {
    responseDetails: {},
    loaded: false
  };

  componentDidMount() {
    const verseObj = this.props.state.verse_info;
    this.setState({
      responseDetails: verseObj,
      loaded: true
    });
    this.props.getDetails(verseObj);

    // axios
    //   .post(
    //     "http://localhost:3011/retreiveVerseFromPendingQueue",
    //     this.props.retreivalID
    //   )
    //   .then(response => {
    //     this.setState({
    //       responseDetails: response.data[0]
    //     });
    //     this.setState({ loaded: true });
    //     this.props.getDetails(this.state.responseDetails);
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
  }

  handleApproval = async () => {
    const reqParam = {
      id: this.props.state.verse_info._id
    };
    await axios
      .post(process.env.REACT_APP_API_ENDPOINT+"/approver/approve", reqParam, { headers: {"Authorization" : `Bearer ${localStorage.getItem("jwtToken")}`} })
      .then(async response => {
        await this.props.runFunction();
      })
      .catch(error => {
        console.log(error);
      }).finally(()=>{this.props.history.goBack()})
  };

  handleRejection = async () => {
    const reqParam = {
      id: this.props.state.verse_info._id,
    };
    await axios
      .post(process.env.REACT_APP_API_ENDPOINT+"/approver/reject", reqParam, { headers: {"Authorization" : `Bearer ${localStorage.getItem("jwtToken")}`} })
      .then(async response => {
        await this.props.runFunction();
      })
      .catch(error => {
        console.log(error);
      }).finally(()=>{this.props.history.goBack()})
    // await this.props.history.goBack();
  };

  render() {
    if (this.state.loaded) {
      console.log('d',this.state.responseDetails)
      return (
        <div
          className="card aos-init aos-animate"
          style={{
            overflowY: "hidden",
            width: "49.5%",
            height: "80vh",
            display: "inline-block"
          }}
          data-aos="fade"
          data-aos-duration={1000}
        >
          <div className="card-body" data-aos="fade" data-aos-duration={800} style={{height: 'inherit'}}>
            <h4
              className="card-title"
              style={{
                textAlign: "center",
                fontWeight: 500,
                fontFamily: "Old English Text MT"
              }}
            >
              <button style={{ display: "none" }} onClick={this.handleApproval}>
                Submit
              </button>
              Chapter <span id="thisChapterName">{this.state.responseDetails.entry_details.chapter_name}</span> |
              Verse No.{" "}
              {this.state.responseDetails.entry_details.verse_details.verse_no}
              <button
                style={{ display: "none" }}
                onClick={this.handleRejection}
              >
                Reject
              </button>
            </h4>
            <h6
              className="text-muted card-subtitle mb-2 hover-glow"
              style={{ textAlign: "center" }}
            >
              Pali (Submitted by{" "}
              {this.state.responseDetails.publisher_details.publisher_name}) | Submit Time {this.state.responseDetails.entry_details.submit_time}
            </h6>
            <p
              className="card-text text-justify"
              style={{
                padding: 5,
                fontWeight: "bold",
                height: "33rem",
                overflowY: "auto",
                fontSize: 14,
                marginTop: 30
              }}
            >
              {this.state.responseDetails.entry_details.verse_details.text}
            </p>
          </div>
        </div>
      );
    } else {
      return <div>Loading...</div>;
    }
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps)(ApproveSplitScreen);
