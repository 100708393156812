import setAuthToken from "../utils/setAuthToken"; //his file will set authorization token when a user signs in, and deletes it when he signs out.
//and has global headers for authentication requests 
import axios from 'axios'
import jwt_decode from "jwt-decode"; //Yep, now we gon' decode the token using the key that we have
import {GET_ERRORS,SET_CURRENT_USER,USER_LOADING} from "./types"; //Self explanatory, and I'm tired of spoon-feeding using comments

// Register User
//This function will be called from Register.js
export const registerUser = (userData, history) => dispatch => { 
  axios.post(process.env.REACT_APP_API_ENDPOINT+"/api/users/register", userData)
    .then(res => history.push("/login")) // re-direct to login on successful register
    .catch(err => dispatch(
                          {
                            type: GET_ERRORS,
                            payload: err.response.data
                        }
                      )
                    );
                };
// Login - get user token
export const loginUser = userData => dispatch => {
  axios.post(process.env.REACT_APP_API_ENDPOINT+"/user/auth", userData)
    .then(res => {
      // Save to localStorage
// Set token to localStorage
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);

      // NOW WE'RE GOING TO DECODE THE TOKEN, TO GET USER DATA
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const changePassword = userData => dispatch => {
  const dataToBePosted = {
    old_password: userData.prevPass,
    new_password: userData.newPass
  }

  axios.post(process.env.REACT_APP_API_ENDPOINT+"/user/reset", dataToBePosted, { headers: {"Authorization" : `Bearer ${localStorage.getItem("jwtToken")}`} })
    .then(res => {
      // console.log(res)
      localStorage.removeItem("jwtToken");
      setAuthToken(false);
      dispatch(setCurrentUser({}));
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};
// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};
// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING
  };
};
// Log user out
export const logoutUser = () => dispatch => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};