import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from "axios"
// import unidecode from "unidecode"
import "./bootstrap.min.css"
import "./spinner.scss"

class SkeletonHere extends Component {
  state = {
    books: [],
    bookchosen: '',
    loaded: false
  }

    componentDidMount(props) {
      document.title = "Pick a book";
        // If logged in and user navigates to Login page, should redirect them to dashboard
        if (!localStorage.getItem("basket_name")) {
          this.props.history.push("/basket");
        }

        if (!localStorage.getItem("canon_name")) {
          localStorage.getItem("atthakatha")
          }

          // const canonname = localStorage.getItem("canon_name")
          const basketname = localStorage.getItem("basket_name")

          axios.get(process.env.REACT_APP_API_ENDPOINT+'/viewer/getmap?school=atthakatha&basket='+basketname)
          .then(response => {
            // console.log(response.data.books)
            const books = response.data.books
            this.setState({books: books, loaded: true})
          })
          .catch(error => {
            console.log(error);
          })  

      }
    
      removeCheckpoints = () => {
        localStorage.setItem("basket_name", "");
        this.props.history.push("/Basket");
      };

      setBook = (event) => {
        this.setState({ bookschosen: "event.target.dataset.book" });
        localStorage.setItem("book_name", event.target.dataset.book);
      }


  render() {
    const showLoader = () => {
      if(!this.state.loaded)
      return (
        <div className="loader" style={{height: '50vh'}}>
        <svg className="circular" viewBox="25 25 50 50">
          <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"/>
        </svg>
      </div>
      )
    }

    const checkFlag = () => {
      if(this.state.loaded)
      return(
        <div className="container">
            <h3 data-aos="fade" data-aos-duration={500}>
              Choose a book.
               {/* You are here: {localStorage.getItem("canon_name")+' > '+localStorage.getItem("basket_name")} */}
              {/* {unidecode("aéà)àçé")} */}
            </h3>
            <div
              className="row"
              data-aos="fade"
              data-aos-duration={500}
              style={{ margin: "0px 10px" }}
            >
              {abc}
             </div>
          </div>
      )
    }
    const abc = this.state.books.map((currElem, i)=>{
      let bookURL = currElem.name.split(' ').join('_')
      const currentURL = this.props.location.pathname
      return(
        <div className="col" data-bs-hover-animate="pulse" key={i}>
            <Link onClick={this.setBook} data-book={currElem.name} 
            to={{pathname: `${currentURL}/Book/${bookURL}/`, state: {chapters: currElem.chapters, currentBookIndex: i}}}>
            {/* to={`Book/${bookURL}/`}> */}
              <div data-book={currElem.name} className="mx-auto features-icons-item mb-5 mb-lg-0 mb-lg-3 hover-glow">
                <div data-book={currElem.name} className="d-flex features-icons-icon">
                  <img
                  alt={currElem.name}
                  data-book={currElem.name}
                    className="icon-layers m-auto text-primary"
                    style={{ height: "4.5rem" }}
                    src="/assets/img/book.png"
                  />
                </div>
                <h3 data-book={currElem.name}>{currElem.name}</h3>
              </div>
            </Link>
          </div>
      )
    })

    return (
        <div>
        <nav className="navbar navbar-light navbar-expand bg-light navigation-clean" style={{background: 'radial-gradient(circle, rgb(255, 221, 64), rgb(162, 141, 42))', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 2px 5px 0px, rgba(0, 0, 0, 0.12) 0px 2px 10px 0px'}}>
          <div className="container">
            <Link className="navbar-brand" style={{color: 'white'}} to="/">
              Tripitaka
            </Link>
            <button value='' onClick={this.removeCheckpoints} className="btn btn-success">
              Start Over
            </button>
            </div>
        </nav>
        <section className="features-icons bg-light text-center" style={{minHeight: 'calc(100vh - 56px)'}}>
        {showLoader()}
          {checkFlag()}
        </section>
       </div>        
       )
  }
}


export default SkeletonHere;
