import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import AOS from "aos";

class About extends Component {
  constructor(props, context) {
    super(props, context);
    AOS.init();
  }

  componentWillReceiveProps() {
    AOS.refresh();
  }

  userCheck = () => {
    if (this.props.auth.isAuthenticated)
      return (
        <li className="nav-item nav-link js-scroll-trigger" role="presentation">
          <Link
            className="nav-link js-scroll-trigger text-center"
            to="/Profile"
          >
            Profile
          </Link>
        </li>
      );
  };

  render() {
    document.body.style.background = "rgb(68, 65, 65)";
    return (
      <div
        style={{
          // background:"-webkit-linear-gradient(to right, rgb(146, 91, 16), rgb(45, 29, 6))" /* Chrome 10-25, Safari 5.1-6 */,
          // background:"linear-gradient(to right, rgb(146, 91, 16), rgb(45, 29, 6))" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */,
          // color: "white",
          minHeight: "100vh",
          background: "#444141",
          color: "white"
        }}
      >
        <nav
          className="navbar navbar-light navbar-expand-md navbar navbar-expand-lg fixed-top"
          id="mainNav"
          style={{
            background:
              "radial-gradient(circle, rgb(255, 221, 64), rgb(162, 141, 42))"
          }}
        >
          <div className="container">
            <Link className="navbar-brand js-scroll-trigger" to="/">
              Tripitaka
            </Link>
            <button
              data-toggle="collapse"
              className="navbar-toggler navbar-toggler-right"
              data-target="#navbarResponsive"
              type="button"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
              value="Menu"
            >
              <i className="fa fa-bars" />
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="nav navbar-nav ml-auto">
                <li
                  className="nav-item nav-link js-scroll-trigger "
                  role="presentation"
                >
                  <Link
                    className="nav-link active js-scroll-trigger text-center"
                    to="/"
                  >
                    Home
                  </Link>
                </li>
                <li
                  className="nav-item nav-link js-scroll-trigger"
                  role="presentation"
                >
                  <Link
                    className="nav-link js-scroll-trigger text-center"
                    to="/Canon"
                  >
                    View
                  </Link>
                </li>
                {this.userCheck()}
                {/* <li class="nav-item nav-link js-scroll-trigger" role="presentation"><a class="nav-link js-scroll-trigger" href="#contact">contact</a></li> */}
              </ul>
            </div>
          </div>
        </nav>
        <section
          id="about"
          className="content-section text-center"
          style={{ paddingTop: "4rem" }}
        >
          <div className="container">
            <div
              id="aboutUsText"
              style={{
                fontFamily:
                  "Segoe UI Light, Lato, Helvetica Neue, Helvetica, Arial, sans-serif"
              }}
              // style={{
              //   border: 0,
              //   boxShadow: '0 0 0px 0px #b5910e, 0 0 84px 2px #42340c, inset 0 0 228px 12px #c1991f, inset 0 0 60px 30px #ffc, -2px 3px 0px 0px #ffc107, 0 0 228px 12px #ffc107'
              // }}
            >
              <div className="col-lg-12">
                <h5 style={{ paddingTop: "5px", fontFamily: "Segoe UI Light" }}>
                  About Us
                </h5>
                <div className="text-justify">
                  <div className="text-center" style={{ fontStyle: "italic" }}>
                    <p style={{fontWeight: '600'}}>
                      For as long as space endures
                      <br />
                      and for as long as living beings remain
                      <br />
                      until then may I too abide
                      <br />
                      to dispel the misery of the world
                      <br />
                      <span style={{fontStyle:'normal'}}>-- Shantideva, 'The Way of the Bodhisattva'</span>
                    </p>
                  </div>

                  <p data-aos="fade" data-aos-duration={800}>
                    Preservation of important texts has always been a foundation
                    stone of any civilization. With the help of more than a 1000
                    monks who had attained <i>Arhantship</i>, the three books of
                    the Tripiṭaka were first compiled and put into writing on
                    palm leaves during the reign of King Valagamba of the
                    Anuradhapura Kingdom, Sri Lanka in first century BCE. The
                    importance of preserving history and wisdom has also been
                    exemplified through the edicts of Emperor Ashoka which were
                    distributed across the Indian subcontinent and Middle-East,
                    and the name of the platform itself is inspired from these
                    same edicts.
                  </p>
                  <p data-aos="fade" data-aos-duration={1200}>
                    In this digital age, we have taken the initiative to use the
                    emerging technology of Blockchain to preserve the Tripiṭaka
                    for future. With the advent of Blockchain technology it has
                    become possible to re-imagine existing systems and
                    re-arrange them in a democratic and secure way. This is made
                    possible through the various properties offered by
                    Blockchain: trust, immutability of data, transparency, and
                    decentralization of power. The technology breaks new ground
                    regarding how we think about authorities, middlemen,
                    organizational hierarchies, dependencies on third-parties
                    and other issues. It is expected to transform our lives more
                    than the introduction of internet.{" "}
                  </p>
                  <p data-aos="fade" data-aos-duration={1600}>
                    For our platform, we are using some of the properties of
                    Blockchain technology to achieve the integrity and security
                    necessary for the preservation of texts. The texts are
                    broken down into units, and are individually reviewed and
                    recorded onto the Blockchain with the approval of ordained
                    monks, bhikkhus, and scholars. Once it is committed to the Blockchain
                    over a distributed network, it will be made available to the
                    public through this platform. The project will follow an
                    incremental approach, slowly adding different texts and
                    spreading across geographies, traversing languages, canons,
                    and different schools of thought.
                  </p>
                  <p>
                    The initial ‘Blocks’ are being approved and uploaded onto the blockchain platform in presence of His Holiness, the
                    14th Dalai Lama, Tenzin Gyatso, on 24<sup>th</sup> November 2019 as a symbolic endorsement of
                    the project. The ceremony is set to take place on the occasion of the <strong><a href='http://www.gbcindia2019.in/' target="_blank" rel="noopener noreferrer">Global
                    Buddhist Congregation</a></strong>, a mega-conference with a noble
                    cause of spreading the teachings of mindfulness, compassion
                    and peace of the Buddha. It is organised under the guidance
                    of All India Bhikkhu Sangha, in the historical city of
                    Aurangabad, in the state of Maharashtra.
                  </p>
                  <p>
                    The project is undertaken under the guidance of Ms. Rojana
                    Vanich Kamble, and support from
                    Dr. Harshadeep Kamble, a senior Indian bureaucrat, and is being supervised by Gaurav
                    Somwanshi, a global Blockchain expert and a
                    Dalai Lama Fellow.
                  </p>
                  <p style={{ float: "right" }}>
                    <Link to="/Policy" target="_blank">Read our Privacy Policy</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps)(About);
