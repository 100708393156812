import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import {
  withRouter,
  Link
} from "react-router-dom";
import AOS from 'aos'; 
import "./spinner.scss";

class Chapters extends Component{

  constructor(props){
    super(props)
    this.state = {
      chapters: [],
      loaded: false
    }

  }

  componentDidUpdate (){ 
    AOS.refresh(); 
  } 

componentDidMount(){
  AOS.init()
  document.title = "Choose a chapter";

  if(this.props.location.state){
    this.setState(
      {
        chapters: this.props.location.state.chapters,
        currentBookIndex: this.props.location.state.currentBookIndex,
        loaded: true
      }
    )
  }
  else{
    console.log("doesn't exist")
    this.getTheChapters()
  }
}

getTheChapters = async () => {
  const book = this.props.match.params.BookName
  const basket = localStorage.getItem("basket_name");
  let response = await axios.get(process.env.REACT_APP_API_ENDPOINT+"/viewer/getmap?school=atthakatha&basket="+basket);
  let tempObj = response.data.books

  const indexOfBook = await tempObj.findIndex(i => i.name === book)
  const bookname = this.props.match.params.BookName
  var chapterObj = await tempObj.filter(async bookie =>  {
    return await bookie.name === bookname
  });
  const chapterArray = chapterObj[0].chapters
  this.setState({
    chapters: chapterArray,
    currentBookIndex: indexOfBook,
    loaded: true
  })
}

removeCheckpoints = () => {
  // localStorage.setItem("canon_name", "");
  localStorage.setItem("basket_name", "");
  this.props.history.push("/Basket");
};

render(){
  const abc = () => {
    if (this.state.loaded) {
      return (
        <div
          className="container"
          // data-aos="fade"
          // data-aos-duration={500}
          style={{ paddingTop: 10 }}
        >
          <div className="row chapters" style={{ margin: "auto" }}>
            <div className="col">
              <ul>
                {this.state.chapters.map((currElem, i) => (
                  <li key={i}>
                    <div className="list">
                      <div className="post-preview">
                        {/* <Link to={`./Chapter/${currElem}/`}>
                            <h4 className="post-subtitle">{currElem}</h4>
                        </Link> */}
                        <Link
                          to={{
                            pathname: `./Chapter/${currElem}/`,
                            state: { chaptersList: this.state.chapters, bookIndex:this.state.currentBookIndex , currentChapterIndex: i }
                          }}
                        >
                          <h4 className="post-subtitle">{currElem}</h4>
                        </Link>
                      </div>
                      <hr />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="loader" style={{ height: "50vh" }}>
          <svg className="circular" viewBox="25 25 50 50">
            <circle
              className="path"
              cx="50"
              cy="50"
              r="20"
              fill="none"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
          </svg>
        </div>
      );
    }
  };
  return (
    <div>
      <nav className="navbar navbar-light navbar-expand bg-light navigation-clean" style={{background: 'radial-gradient(circle, rgb(255, 221, 64), rgb(162, 141, 42))', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 2px 5px 0px, rgba(0, 0, 0, 0.12) 0px 2px 10px 0px'}}>
          <div className="container">
            <Link className="navbar-brand" style={{color: 'white'}} to="/">
              Tripitaka
            </Link>
            <button value='' onClick={this.removeCheckpoints} className="btn btn-success">
              Start Over
            </button>
            </div>
        </nav>
        
      <section
        className="features-icons bg-light text-center"
        style={{ minHeight: "calc(100vh - 56px)", paddingTop: '1rem' }}
      >
        <h3 
        // data-aos="fade" data-aos-duration="500"
         style={{ margin: "0 10" }}>
          Choose chapter
        </h3>
        <div className="container" style={{ paddingTop: 30 }}>
          {abc()}
        </div>
      </section>
    </div>
  )
}
}

const mapStateToProps = state => ({
  auth: state.auth
});
export default withRouter(connect(mapStateToProps)(Chapters));
