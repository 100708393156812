import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { changePassword } from "../../../../actions/authActions";
import ProfileNavbar from "./ProfileNavbar";
import classnames from "classnames";

class PasswordChange extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      prev: "",
      new: "",
      new2: "",
      errors: {},
      newpassworderror: null
    };
  }

  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/");
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // if (nextProps.auth.isAuthenticated) {
    //   nextProps.history.push("/dashboard"); // push user to dashboard after they login
    // }
    if (nextProps.errors) {
      return ({
        errors: nextProps.errors
      });
    }
    
  }

  passwordChangeHandler = e => {
    e.preventDefault();
    const postParams = {
      prevPass: this.state.prev,
      newPass: this.state.new,
      newPass2: this.state.new2
    };
    if(postParams.newPass===postParams.newPass2){
      this.props.changePassword(postParams);
    }
    else{
      this.setState({
        newpassworderror: "Passwords don't match"
      })
      console.log('passwords not matching')
    }
  };

  render() {
    const { errors } = this.state;
    return (
      <div>
        <ProfileNavbar />
        <section
          className="testimonials text-center bg-light"
          style={{
            paddingTop: "4rem",
            paddingBottom: "0",
            minHeight: "calc(100vh - 56px)"
          }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="card shadow-lg o-hidden border-0 my-5">
                  <div
                    className="card-body p-0"
                    style={{boxShadow:"0 0 0px 0px #b5b527, 0 0 6px 2px #42340c, inset 0 0 228px 12px #e8b721, inset 0 0 0px 0px #ffc, 0px 1px 0px 0px #ffc107, 0 0 228px 12px #ffc107"}}
                  >
                    <div className="row">
                      <div className="col">
                        <div className="p-5">
                          <div className="text-center">
                            <h4 className="text-dark mb-4">Change Password</h4>
                          </div>
                          <form
                            className="user"
                            noValidate
                            onSubmit={this.passwordChangeHandler}
                          >
                            <div className="form-group">
                              {/* Old password */}
                              <input
                                error={errors.old_password}
                                className={classnames("form-control", {
                                  invalid: errors.old_password
                                })}
                                type="password"
                                placeholder="Enter Current Password"
                                value={this.state.prev}
                                onChange={e => {
                                  this.setState({ prev: e.target.value });
                                }}
                              />
                              <span style={{ color: "red", fontSize: 12 }}>
                                {errors.old_password}
                                {errors.passwordincorrect}
                              </span>
                            </div>
                            <div className="form-group">
                              {/* New Password */}
                              <input
                                error={errors.new_password}
                                className={classnames("form-control", {
                                  invalid:
                                    errors.new_password ||
                                    errors.new_password_empty
                                })}
                                type="password"
                                placeholder="Enter New Password"
                                value={this.state.new}
                                onChange={e => {
                                  this.setState({ new: e.target.value });
                                }}
                              />
                              <span style={{ color: "red", fontSize: 12 }}>
                                {errors.new_password}
                                {errors.new_password_empty}
                              </span>
                            </div>
                            <div className="form-group">
                              {/* Repeat new password */}
                              <input
                                error={errors.password2}
                                className={classnames("form-control", {
                                  invalid: errors.password2
                                })}
                                type="password"
                                value={this.state.new2}
                                onChange={e => {
                                  this.setState({ new2: e.target.value });
                                }}
                                placeholder="Repeat New Password"
                              />
                              <span style={{ color: "red", fontSize: 12 }}>
                                {/* {errors.password2} */}
                                {this.state.newpassworderror}
                              </span>
                            </div>
                            <div className="form-group btn-container">
                              <button className="btn btn-primary btn-block text-white btn-user">
                                Change Password
                              </button>
                            </div>

                            <hr />
                            <p>
                              <b>Note:</b> After you change your password,
                              you'll be logged out.
                            </p>
                            <hr />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

PasswordChange.propTypes = {
  changePassword: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(
  mapStateToProps,
  { changePassword }
)(PasswordChange);
