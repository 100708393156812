import React, { Component } from 'react';
import { Link } from "react-router-dom";
import AOS from 'aos'; 

class SkeletonHere extends Component {
    
      constructor(props, context) { 
        super(props, context); 
        this.state = {
          canon: ""
        }; 
        AOS.init();

      } 
    
      componentWillReceiveProps (){ 
        AOS.refresh(); 
      } 

      componentDidMount() {
        localStorage.setItem("canon_name",'atthakatha')
        this.props.history.push("/Basket")
        // const canon = localStorage.getItem("canon_name");
        // this.setState({ canon });
        // if (localStorage.getItem("basket_name")) {
        //   this.props.history.push("/Basket");
        // }
      }
    
      setCanon = event => {
        this.setState({ canon: "event.target.dataset.canon" });
        console.log(event.target)
        localStorage.setItem("canon_name", event.target.dataset.canon);
        // let path = `basket`;
        // this.props.history.push(path);
      };


  render() {
     return (
      <div>
      <nav className="navbar navbar-light navbar-expand bg-light navigation-clean">
        <div className="container">
            <Link to='/'>Tripitaka</Link>
          <button
            data-toggle="collapse"
            className="navbar-toggler"
            data-target="#navcol-1"
          />
          <div className="collapse navbar-collapse" id="navcol-1">
            {/* <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="./part1-language.html">
                  <span>Language</span>
                </a>
              </li>
              <li className="breadcrumb-item">
                <Link to="/canon">
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;Canon</span>
                </Link>
              </li>
            </ol> */}
            {/* <a class="btn btn-primary ml-auto" role="button" href="#">Know more</a> */}
          </div>
        </div>
      </nav>
      <section className="features-icons bg-light text-center">
        <div className="container">
          <h3 data-aos="fade" data-aos-duration={500}>
            Choose canon
          </h3>
          <div
            className="row"
            data-aos="fade"
            data-aos-duration={500}
            style={{ margin: "0px 10px" }}
          >
            <div className="col-lg-4" data-bs-hover-animate="pulse">
              <div  className="mx-auto features-icons-item mb-5 mb-lg-0 mb-lg-3 hover-glow">
                <Link data-canon="mula" onClick={this.setCanon} to='/Basket'>
                  
                  <div data-canon="mula" className="d-flex features-icons-icon">
                    <img
                      data-canon="mula"
                      className="icon-layers m-auto text-primary"
                      style={{ height: "4.5rem" }}
                      src="./assets/img/mula.png"
                      alt="mula"
                    />
                  </div>
                  <h3 data-canon="mula">Mula</h3>

                </Link>
              </div>
            </div>
            <div className="col-lg-4" data-bs-hover-animate="pulse">
              <Link data-canon="aṭṭhakathā" onClick={this.setCanon} to='/Basket'>
                <div data-canon="aṭṭhakathā" className="mx-auto features-icons-item mb-5 mb-lg-0 mb-lg-3 hover-glow">
                  <div data-canon="aṭṭhakathā" className="d-flex features-icons-icon">
                    <img
                    data-canon="aṭṭhakathā"
                    alt="aṭṭhakathā"
                      className="icon-layers m-auto text-primary"
                      style={{ height: "4.5rem" }}
                      src="./assets/img/attakatha.png"
                    />
                  </div>
                  <h3 data-canon="aṭṭhakathā">Aṭṭhakathā</h3>
                </div>
              </Link>
            </div>
            <div className="col-lg-4" data-bs-hover-animate="pulse">
            <Link data-canon="ṭīkā" to='/Basket' onClick={this.setCanon}>
                <div data-canon="ṭīkā" className="mx-auto features-icons-item mb-5 mb-lg-0 mb-lg-3 hover-glow">
                  <div data-canon="ṭīkā" className="d-flex features-icons-icon">
                    <img
                    data-canon="ṭīkā"
                    alt="ṭīkā"
                      className="icon-layers m-auto text-primary"
                      style={{ height: "4.5rem" }}
                      src="./assets/img/tika.png"
                    />
                  </div>
                  <h3 data-canon="ṭīkā">Ṭīkā</h3>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="showcase" />
      <footer className="footer bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 my-auto h-100 text-center text-lg-left">
              <ul className="list-inline mb-2">
                <li className="list-inline-item">
                  <Link to="/About">About</Link>
                </li>
                <li className="list-inline-item">
                  <span>⋅</span>
                </li>
                <li className="list-inline-item">
                  <span>⋅</span>
                </li>
              </ul>
              <p className="text-muted small mb-4 mb-lg-0">
                © DhammaEdicts 2019. All Rights Reserved.
              </p>
            </div>
            {/* <div className="col-lg-6 my-auto h-100 text-center text-lg-right">
              <ul className="list-inline mb-0">
                <li className="list-inline-item">
                  <a href="#">
                    <i className="fa fa-facebook fa-2x fa-fw" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#">
                    <i className="fa fa-twitter fa-2x fa-fw" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#">
                    <i className="fa fa-instagram fa-2x fa-fw" />
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </footer>
    </div>    
       )
  }
}


export default SkeletonHere;
