import { createStore, applyMiddleware, compose } from "redux";
// createStore is used to help create a store, applyMiddleware is used to use thunk and compose is 
import thunk from "redux-thunk";
// Redux-thunk is a middleware used to directly access the dispatch method
import rootReducer from "./reducers"; //CombineReducer is now imported as rootReducer
const initialState = {}; //because before the first render, the overall shared state will be empty.
// the states to be dispatched to container components (and further to presentational components) will be provided by the reducer
const middleware = [thunk];

// createStore syntax: createStore(reducer, [preloadedState], [enhancer])
const store = createStore(
  rootReducer,
  initialState,
  compose(
    applyMiddleware(...middleware), //we use the applyMiddleware function on the thunk, because thunk is a middleware, and because it is common sense -_-
  )
);

export default store;