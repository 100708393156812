import React, { Component } from 'react'
import { connect } from "react-redux";
import axios from "axios"
import ProfileNavbar from './ProfileNavbar';

class ApprovedVerses extends Component{
    state = {
        loaded: false,
        approvedVerses: [],
    }

    componentDidMount() {
        axios.get(process.env.REACT_APP_API_ENDPOINT+'/publisher/getApproved', { headers: {"Authorization" : `Bearer ${localStorage.getItem("jwtToken")}`} })
        .then(response => {
          this.setState({approvedVerses: response.data, loaded: true})
        })
        .catch(error => {
          console.log(error);
        })
        window.scrollTo(0, 0);

    }

    render() {
        const rejTable = () => {
          if (this.props.auth.user.usertype.toLowerCase() !== "publisher") {
            console.log("You are not authorized");
          } else {
            if (this.state.loaded) {
              if (this.state.approvedVerses.length === 0) {
                return <tr>
                <td colSpan='6'>No approved verse found.</td>
                </tr>
              } else {
                return this.state.approvedVerses.map((currEl, i) => {
                  return (
                    <tr key={i}>
                      <th scope="row">{i + 1}</th>
                      <td><span>{currEl.entry_details.book_name}</span></td> 
                      <td><span>{currEl.entry_details.chapter_name}</span></td>
                      <td><span>{currEl.entry_details.verse_details.verse_no}</span></td>
                      <td><p className="line-clamp line-clamp-1">{currEl.entry_details.verse_details.text}</p></td>
                      <td><span>{currEl.approver_name}</span></td>
                      <td>
                        <button
                          onClick={() => {
                            this.props.history.push({
                              pathname: "/Profile/MySubmitted/View",
                              state: {
                                type: 'submitted',
                                currentView: currEl
                              }
                            });
                          }}
                          className="btn btn-success"
                          style={{ textTransform: "none" }}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  );
                });
              }
            } else {
              return "Please wait, loading...";
            }
          }
        };
    
        return (
          <div>
            <ProfileNavbar />
            <section
              className="testimonials text-center bg-light"
              style={{ minHeight: "calc(100vh - 56px)" }}
            >
              <div className="container">
                <h2 className="mb-5">
                  Following verses have been published by you
                </h2>
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Book</th>
                      <th scope="col">Chapter</th>
                      <th scope="col">Verse</th>
                      <th scope="col">Text</th>
                      <th scope="col">Approved by</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>{rejTable()}</tbody>
                </table>
                {/* <div className="row">{rejectedQuery()}</div> */}
              </div>
            </section>
          </div>
        );
      }
}
const mapStateToProps = state => ({
    auth: state.auth
});
export default connect(mapStateToProps)(ApprovedVerses);