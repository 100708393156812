import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import ProfileNavbar from "./ProfileNavbar";
class Review extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      verses: [],
      selectedVerses: []
    };
  }

  componentDidMount() {
    this.reRenderFunction();
  }

  rejectSelectedVerses = () => {
    const reqParam = {
      ids: this.state.selectedVerses
    };
    axios
      .post(process.env.REACT_APP_API_ENDPOINT+"/approver/rejectmulti", reqParam, {
        headers: { Authorization: `Bearer ${localStorage.getItem("jwtToken")}` }
      })
      .then(response => {
        // this.props.runFunction()
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.reRenderFunction();
        this.setState({
          selectedVerses: []
        });
        var clist = document.getElementsByTagName("input");
        for (var i = 0; i < clist.length; ++i) {
          clist[i].checked = false;
        }
      });
  };

  approveSelectedVerses = () => {
    const reqParam = {
      ids: this.state.selectedVerses
    };
    axios
      .post(process.env.REACT_APP_API_ENDPOINT+"/approver/approvemulti", reqParam, {
        headers: { Authorization: `Bearer ${localStorage.getItem("jwtToken")}` }
      })
      .then(response => {
        // this.reRenderFunction()
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.reRenderFunction();
        this.setState({
          selectedVerses: []
        });
        var clist = document.getElementsByTagName("input");
        for (var i = 0; i < clist.length; ++i) {
          clist[i].checked = false;
        }
      });
  };

  reRenderFunction = () => {
    axios
      .get(process.env.REACT_APP_API_ENDPOINT+"/approver/reviewPending", {
        headers: { Authorization: `Bearer ${localStorage.getItem("jwtToken")}` }
      })
      .then(response => {
        const verseResults = response.data;
        this.setState({ verses: verseResults, loaded: true });
      })
      .catch(error => {
        console.log(error);
      });
  };

  checkVerse = something => {
    const abc = something;
    let obj = this.state.selectedVerses;
    const index = obj.findIndex(eelee => eelee === abc);
    if (index === -1) {
      obj.push(something);
      this.setState({
        selectedVerses: obj
      });
    } else {
      obj = [...obj.slice(0, index), ...obj.slice(index + 1)];
      this.setState({
        selectedVerses: obj
      });
    }

    console.log(this.state.selectedVerses);
  };

  render() {
    const abc = () => {
      // const { match, location, history } = this.props;
      if (this.props.auth.user.usertype.toLowerCase() !== "approver") {
        this.props.history.push("/dashboard");
      } else {
        if (this.state.loaded) {
          if (this.state.verses.length > 0) {
            return this.state.verses.map((currEl, i) => {
              return (
                <div key={i}>
                  <div
                    className="card"
                    style={{
                      border: "none",
                      marginLeft: "10px",
                      background: "transparent"
                    }}
                  >
                    <div className="row">
                      <CheckboxInput
                        checkVerse={this.checkVerse}
                        verseId={currEl._id}
                      />
                      <div className="col-8">
                        <div
                          className="card-body"
                          style={{
                            marginBottom: "2rem",
                            boxShadow:
                              "0 0 0px 0px #b5b527, 0 0 84px 2px #d0d0d0, inset 0 0 228px 12px #c1991f, inset 0 0 60px 30px #ffc, 4px 4px 0px 0px #ffc107, 0 0 9px 1px #ffc107"
                          }}
                        >
                          <h5 className="card-title">
                            {currEl.entry_details.book_name}{" "}
                            {currEl.entry_details.chapter_name}{" "}
                            {currEl.entry_details.verse_details.verse_no}
                          </h5>
                          <h6
                            className="card-subtitle mb-2"
                            style={{ color: "white" }}
                          >
                            {currEl.publisher_details.publisher_name}
                          </h6>
                          <p className="card-text text-justify line-clamp line-clamp-3">
                            {currEl.entry_details.verse_details.text}
                          </p>
                        </div>
                      </div>
                      <ButtonClass
                        proppo={currEl._id}
                        verses={this.state.verses}
                        runFunction={this.reRenderFunction}
                        history={this.props.history}
                      />
                    </div>
                  </div>
                </div>
              );
            });
          } else {
            return "No verse awaiting approval";
          }
        } else {
          return "Please wait, loading...";
        }
      }
    };
    // const { user } = this.props.auth;
    return (
      <div>
        <ProfileNavbar />
        <section
          className="testimonials text-center bg-light"
          style={{ paddingTop: "4rem", minHeight: "calc(100vh - 56px)" }}
        >
          <div className="container">
            <div className="mb-5">
              <h3>Review submitted verses</h3>
              <div>
                {/* <button className="btn btn-warning"  style={{marginRight: '5px'}}>Select All</button> */}
                <button
                  onClick={this.approveSelectedVerses}
                  className="btn btn-success"
                  style={{ marginRight: "5px" }}
                >
                  Approve
                </button>
                <button
                  onClick={this.rejectSelectedVerses}
                  className="btn btn-danger"
                  style={{ marginRight: "5px" }}
                >
                  Reject
                </button>
              </div>
            </div>

            <div>{abc()}</div>
          </div>
        </section>
      </div>
    );
  }
}

class CheckboxInput extends Component {
  render() {
    return (
      <input
        type="checkbox"
        onChange={() => {
          this.props.checkVerse(this.props.verseId);
        }}
        name="verseForApproval"
        className="my-auto"
        style={{ height: "30px", width: "30px" }}
      />
    );
  }
}

class ButtonClass extends Component {
  handleViewDecision = () => {
    const newParam = {
      id: this.props.proppo
    };
    const statecopy = this.props.verses;
    let filterFun = statecopy.filter(function(verse) {
      return verse._id === newParam.id;
    });

    const currVerDetails = filterFun[0];
    this.props.history.push({
      pathname: "/ApproveView",
      state: { verse_info: currVerDetails }
    });
  };

  handleRemoveDecision = () => {
    const reqParam = {
      id: this.props.proppo
    };
    axios
      .post(process.env.REACT_APP_API_ENDPOINT+"/approver/reject", reqParam, {
        headers: { Authorization: `Bearer ${localStorage.getItem("jwtToken")}` }
      })
      .then(response => {
        this.props.runFunction();
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleApproveDecision = () => {
    const reqParam = {
      id: this.props.proppo
    };
    axios
      .post(process.env.REACT_APP_API_ENDPOINT+"/approver/approve", reqParam, {
        headers: { Authorization: `Bearer ${localStorage.getItem("jwtToken")}` }
      })
      .then(response => {
        this.props.runFunction();
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    return (
      <div className="col my-auto">
        <button
          onClick={this.handleApproveDecision}
          style={{ borderRadius: "50%" }}
          className="btn btn-success card-link"
        >
          <i className="fa fa-check" aria-hidden="true" />
        </button>
        <button
          onClick={this.handleRemoveDecision}
          style={{ borderRadius: "50%" }}
          className="btn btn-danger card-link"
        >
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <button
          onClick={this.handleViewDecision}
          style={{ borderRadius: "50%" }}
          className="btn btn-info card-link"
        >
          <i className="fa fa-eye" aria-hidden="true" />
        </button>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps)(Review);
