import React, { Component } from 'react'
import { connect } from "react-redux";
import ApproverProfile from "./ApproverProfile"
import PublisherProfile from "./PublisherProfile"

class Profile extends Component{
    componentDidMount(){
        if(!this.props.auth.isAuthenticated){
            this.props.history.push('/')
        }
    }
    render(){
        document.body.style.background = "#f8f9fa";
        const { user } = this.props.auth;
        const userType = user.usertype.toLowerCase()
        if(userType === "approver"){
            return(<ApproverProfile/>)
        }
        else if(userType==="publisher"){
            return(<PublisherProfile/>)
        }
        else{
            return(
                <div>Wait, how did you get here?</div>
            )
        }
    }
}
const mapStateToProps = state => ({
    auth: state.auth
});
export default connect(mapStateToProps)(Profile);