import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./video-bootstrap.css";
import AOS from 'aos'; 

class Dashboard extends React.Component {
  constructor(props, context) { 
    super(props, context); 
    AOS.init(); 
  } 

  componentDidUpdate (){ 
    AOS.refresh(); 
  } 

  componentDidMount() {
    document.title = "Welcome to Tripitaka";
    if (!localStorage.getItem("script")) {
      localStorage.setItem("script", "roman");
    }
  }

  render() {
    const { user } = this.props.auth;
    const abc = function() {
      if (user.usertype) {
        console.log("Yep");
        return (
          <li
            className="nav-item nav-link js-scroll-trigger text-center"
            role="presentation"
          >
            <Link className="nav-link js-scroll-trigger" to="/Profile">
              Profile
            </Link>
          </li>
        );
      } else {
        console.log("nope");
        return null;
      }
    };

    return (
      <div className="bootstrap-video" style={{height: '100vh'}}>
        <nav
          className="navbar navbar-light navbar-expand-md navbar navbar-expand-lg fixed-top"
          id="mainNav"
          // style={{
          //   background: 'radial-gradient(circle, rgb(255, 221, 64), rgb(162, 141, 42))'
          // }}
        >
          <div className="container">
            <Link className="navbar-brand js-scroll-trigger" to="/">
              Tripitaka
            </Link>
            <button
              data-toggle="collapse"
              className="navbar-toggler navbar-toggler-right"
              data-target="#navbarResponsive"
              type="button"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
              value="Menu"
            >
              <i className="fa fa-bars" />
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="nav navbar-nav ml-auto">
                <li
                  className="nav-item nav-link js-scroll-trigger text-center"
                  role="presentation"
                >
                  <Link className="nav-link js-scroll-trigger" to="/About">
                    About
                  </Link>
                </li>
                <li
                  className="nav-item nav-link js-scroll-trigger text-center"
                  role="presentation"
                >
                  <Link to="/canon" className="nav-link js-scroll-trigger">
                    View
                  </Link>
                </li>
                {abc()}
                <ScriptSelector />
              </ul>
            </div>
          </div>
        </nav>
        {/* <video
          autoPlay
          muted
          loop
          id="myVideo"
          style={{
            position: "fixed",
            right: 0,
            bottom: 0,
            minWidth: "100%",
            minHeight: "100%"
          }}
        >
          <source src="./assets/vid/video.mp4" type="video/mp4" />
          Your browser does not support HTML5 video.
        </video> */}
        <header
          className="masthead"
          style={{
            background: "url(/assets/img/buddhabg.jpg)",
            backgroundPosition: "50% 0",
            display: "table",
            width: "100%",
            padding: "200px 0px",
            textAlign: "center",
            backgroundSize: "cover",
            height: '100vh'
          }}
        >
          <div className="intro-body">
            <div className="container">
            <div
                    data-aos="fade"
                    data-aos-duration={2800}
                    id="mastheadercontent"
                    style={{ position: "relative" }}
                  >
                    <h1
                    data-aos="fade"
                    data-aos-duration={500}
                    className="brand-heading"
                    style={{
                      color: "white",
                      // position: "relative",
                      // bottom: "9rem",
                      textShadow: "2px 2px 5px #FF0000"
                    }}
                  >
                    TRIPITAKA
                  </h1>
                  
                    <p
                      className="intro-text"
                      style={{ textShadow: "3px 3px 5px black" }}
                    >
                      Blockchain-backed scriptures
                    </p>
                    <p></p>
                    <button
                      className="btn btn-link btn-box"
                      style={{ border: "1px solid white", color: "white" }}
                      onClick={() => {
                        this.props.history.push("/canon");
                      }}
                    >
                      View Scriptures
                    </button>
                  </div>
                
            </div>
          </div>
        </header>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps)(Dashboard);

class ScriptSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      script: localStorage.getItem("script")
    };
  }

  scriptCheck = () => {
    switch(this.state.script){
      case "roman":
        return "English"
      case "devanagari":
        return "देवनागरी"
      default:
        return "English"
    }
  }

  setScript = e => {
    localStorage.setItem("script", e.target.value);
    this.setState({
      script: localStorage.getItem("script")
    });
  };

  render() {
    return (
      <li className="nav-item nav-link dropdown">
        <button
          className="dropdown-toggle nav-link button-as-link mx-auto"
          style={{ color: "white", textTransform: "uppercase" }}
          data-toggle="dropdown"
          aria-expanded="false"
          href="#"
        >
          {/* {this.state.script}{" "} */}
          {this.scriptCheck()}
        </button>
        <div className="dropdown-menu" role="menu">
          <button
            onClick={this.setScript}
            value="roman"
            className="dropdown-item"
            role="presentation"
            href="#"
            style={{ color: "black", textAlign: 'center' }}
          >
            ENGLISH
          </button>
          <button
            onClick={this.setScript}
            value="devanagari"
            className="dropdown-item"
            role="presentation"
            href="#"
            style={{ color: "black", textAlign: 'center' }}
          >
            देवनागरी
          </button>
        </div>
      </li>
    );
  }
}
