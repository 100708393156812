import React, { Component } from "react";
import { connect } from "react-redux";
import ProfileNavbar from "./ProfileNavbar";
import axios from "axios";

class VersesRejectedByApprover extends Component {
  state = {
    loaded: false,
    rejloaded: false,
    publishedVerses: []
  };

  componentDidMount() {
    axios
      .get(process.env.REACT_APP_API_ENDPOINT+"/approver/committed", { headers: {"Authorization" : `Bearer ${localStorage.getItem("jwtToken")}`} })
      .then(response => {
        console.log(response.data)
        this.setState({
          publishedVerses: response.data,
          rejloaded: true
        })
      })
      .catch(error => {
        console.log(error);
      });
      window.scrollTo(0, 0);

  }

  clickHandler = e => {
    console.log(e.target);
  };

  render() {
    const rejTable = () => {
      if (this.props.auth.user.usertype.toLowerCase() !== "approver") {
        console.log("You are not authorized");
      } else {
        if (this.state.rejloaded) {
          if (this.state.publishedVerses.length === 0) {
            return <tr>
            <td colSpan='6'>No published verse found.</td>
            </tr>
          } else {
            console.log(this.state.publishedVerses[0])
            return this.state.publishedVerses.map((currEl, i) => {
              return (
                <tr key={i}>
                  <th scope="row">{i + 1}</th>
                  <td><span>{currEl.entry_details.book_name}</span></td> 
                  <td><span>{currEl.entry_details.chapter_name}</span></td>
                  <td><span>{currEl.entry_details.verse_details.verse_no}</span></td>
                  <td><p className="line-clamp line-clamp-1">{currEl.entry_details.verse_details.text}</p></td>
                  {/* <td><span style={{fontSize: '10px'}}>{currEl.approval_time}</span></td>  */}
                  {/* <td><span>{currEl.publisher_details.approver_name}</span></td> */}
                  <td>
                    <button
                      onClick={() => {
                        this.props.history.push({
                          pathname: "/Profile/MySubmitted/View",
                          state: {
                            type: 'submitted',
                            currentView: currEl
                          }
                        });
                      }}
                      className="btn btn-success"
                      style={{ textTransform: "none" }}
                    >
                      View
                    </button>
                  </td>
                </tr>
              );
            });
          }
        } else {
          return "Please wait, loading...";
        }
      }
    };

    return (
      <div>
        <ProfileNavbar />
        <section
          className="testimonials text-center bg-light"
          style={{ minHeight: "calc(100vh - 56px)" }}
        >
          <div className="container">
            <h2 className="mb-5">
              Following verses have been published by you
            </h2>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Book</th>
                  <th scope="col">Chapter</th>
                  <th scope="col">Verse</th>
                  <th scope="col">Text</th>
                  {/* <th scope="col">Date</th> */}
                  {/* <th scope="col">Approved by</th> */}
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>{rejTable()}</tbody>
            </table>
            {/* <div className="row">{rejectedQuery()}</div> */}
          </div>
        </section>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps)(VersesRejectedByApprover);
