import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logoutUser } from "../../../../actions/authActions";
import { withRouter } from "react-router-dom";
import ProfileNavbar from "./ProfileNavbar";

class PublisherProfile extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };

  render() {
    const { user } = this.props.auth;
    return (
      <div>
        <ProfileNavbar />
        <section className="testimonials text-center bg-light">
          <h5 className="mb-5">Welcome, {user.name}. You are a publisher.</h5>
          <div className="container">
            <h2 className="mb-5">
              Verses that you have submitted can be found here.
            </h2>
            <div className="row">
              <div className="col-sm-4">
                <div className="mx-auto testimonial-item mb-5 mb-lg-0 profile-selectable-item" onClick={() => {
                    this.props.history.push("/Profile/Approved");
                  }}>
                  <i
                    className="fa fa-thumbs-o-up"
                    style={{ fontSize: "10em" }}
                  />
                  <h5>Approved Verses</h5>
                  <p className="font-weight-light mb-0">
                    Your submitted verses that have been added to the Blockchain
                  </p>
                </div>
              </div>
              <div className="col-sm-4">
                <div
                  className="mx-auto testimonial-item mb-5 mb-lg-0 profile-selectable-item"
                  onClick={() => {
                    this.props.history.push("/Profile/Pending");
                  }}
                >
                  <i className="fa fa-clock-o" style={{ fontSize: "10em" }} />
                  <h5>Pending Verses</h5>
                  <p className="font-weight-light mb-0">
                    Your submitted verses that are yet to be moderated
                  </p>
                </div>
              </div>
              <div className="col-sm-4">
                <div
                  onClick={() => {
                    this.props.history.push("/Profile/Rejected");
                  }}
                  className="mx-auto testimonial-item mb-5 mb-lg-0 profile-selectable-item"
                >
                  <i
                    className="fa fa-thumbs-o-down"
                    style={{ fontSize: "10em" }}
                  />
                  <h5>Rejected Verses</h5>
                  <p className="font-weight-light mb-0">
                    Your submitted verses that were discarded by the moderators
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});
export default withRouter(
  connect(mapStateToProps, { logoutUser })(PublisherProfile)
);
