// This file will set authorization token when a user signs in, and deletes it when he signs out.
import axios from "axios";
const setAuthToken = token => {
  if (token) {
    // Apply authorization token to every request if logged in /*VERY IMPORTANT POINT*/
    // Ergo, set global headers using the axios.defaults.headers.common
    axios.defaults.headers.common["Authorization"] = token;
  } else {
    // Delete auth header
    delete axios.defaults.headers.common["Authorization"];
  }
};
export default setAuthToken;