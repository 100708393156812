import React, { Component } from 'react'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import { logoutUser } from "../../../../actions/authActions"
import ProfileNavbar from './ProfileNavbar';
class ApproverProfile extends Component{
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

  constructor(props){
    super(props)
    this.state = {
      loaded: false,
      verses: []
      }
    this.navigateMe = this.navigateMe.bind(this)
  }
  
  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };

  navigateMe = () => {
   this.props.history.push('/Profile/Review')
  }

    getUsersName = () => {
        if(this.props.auth.user.usertype.toLowerCase() === "approver")
        {
            return "an approver"
        }
    }

    backHandler = () => {
      this.props.history.goBack()
    }


    mySubmitHandler = () => {
      this.props.history.push('Profile/MySubmitted')
    }

    render(){
        const { user } = this.props.auth;
            return(
              <div>
                <ProfileNavbar />
               <section className="testimonials text-center bg-light" style={{ paddingTop: "6rem", minHeight: 'calc(100vh - 56px)' }} >
                  <h5 className="mb-4">Welcome, {user.name}. You are {this.getUsersName()}.</h5>
                  <div className="container">
                    <div className="row">
                    <div className="col-lg-4 divider-thingy" >
                    <div style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 2px 14px 6px',borderRadius: '1rem',overflow: 'hidden', paddingBottom: '10px'}}>

                      <div className="mb-5">
                          <h4 style={{backgroundColor: 'darkred',color: 'white'}}>Awaiting moderation</h4>
                          <p>Moderate submitted verses</p>
                      </div>
                        <div className="mx-auto testimonial-item mb-5 mb-lg-0 profile-selectable-item" onClick={this.navigateMe}>
                          <i className="fa fa-low-vision" style={{ fontSize: "10em" }} />
                          <h5>Review Submitted Verses</h5>
                          <p className="font-weight-light mb-0">Approve or reject verses submitted by publishers</p>
                        </div>
                        </div>
                      </div>
                      <div className="col" >
                        <div style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 2px 14px 6px',borderRadius: '1rem',overflow: 'hidden', paddingBottom: '10px'}}>
                        <div className="mb-5">
                          <h4 style={{backgroundColor: 'darkgreen',color: 'white'}}>Past actions</h4>
                          <p>Your actions on verses will be displayed here</p>
                      </div>
                        
                        <div className="row">
                          <div className="col-lg">
                            <div className="mx-auto testimonial-item mb-5 mb-lg-0 profile-selectable-item" onClick={this.mySubmitHandler}>
                              <i className="fa fa-check-square-o" style={{ fontSize: "10em" }} />
                              <h5>My Submitted Verses</h5>
                              <p className="font-weight-light mb-0">
                                Your published verses
                              </p>
                            </div>
                          </div>
                          <div className="col-lg">
                            <div className="mx-auto testimonial-item mb-5 mb-lg-0 profile-selectable-item" onClick={()=>{this.props.history.push('Profile/MyApproved')}}>
                              <i className="fa fa-thumbs-o-up" style={{ fontSize: "10em" }} />
                              <h5>Approved Verses</h5>
                              <p className="font-weight-light mb-0">
                                The verses that you approved
                              </p>
                            </div>
                          </div>
                          <div className="col-lg">
                            <div className="mx-auto testimonial-item mb-5 mb-lg-0 profile-selectable-item" onClick={()=>{this.props.history.push('Profile/RejectedVerses')}}>
                              <i className="fa fa-thumbs-o-down" style={{ fontSize: "10em" }} />
                              <h5>Rejected Verses</h5>
                              <p className="font-weight-light mb-0">
                                Submitted verses that were rejected by you
                              </p>
                            </div>
                          </div>
                        </div>
                      
                        </div>
                      </div>
                      </div>
                  </div>
                </section>
              </div>
            )
    }
}

ApproverProfile.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};


const mapStateToProps = state => ({
  auth: state.auth
});
export default withRouter(connect(mapStateToProps, {logoutUser})(ApproverProfile));